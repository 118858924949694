<template>
    <form @submit.prevent="submit">
      <!-- Username -->
      <input-label>
        Username
      </input-label>
          <v-text-field
          v-model="username"
          flat
          clearable
          :error-messages = "errorMessages.usernameMsg"
          hint="No spaces or special characters allowed. Use _ or - instead."
          label="Your new username"
          outlined
          type="text"
          counter = "30"
          @input="onType()"
          :rules="[rules.counter, rules.spelling]"
          solo
        ></v-text-field>
      <!-- / Username -->
      <!-- Bio -->
      <input-label>
        Biography
      </input-label>
        <v-textarea
          v-model="bio"
          flat
          hint=""
          label="Tell the world your story!"
          name="profile-settings-bio"
          outlined
          solo
        ></v-textarea>
      <!-- / Bio -->
      <!-- Email -->
      <input-label>
        Email Address
      </input-label>
        <v-text-field
          v-model="email"
          flat
          clearable
          label=""
          outlined
          :rules="[rules.checkEmail]"
          solo
        ></v-text-field>
      <!-- / email -->
      <!-- Links -->
      <input-label>
        Links
      </input-label>
        <v-text-field
          v-model="links.twitter"
          prepend-icon="mdi-twitter"
          flat
          label="YourTwitterHandle"
          outlined
          solo
        ></v-text-field>
        <v-text-field
          v-model="links.instagram"
          prepend-icon="mdi-instagram"
          flat
          label="Your Instagram Handle"
          outlined
          solo
        ></v-text-field>
        <v-text-field
          v-model="links.facebook"
          prepend-icon="mdi-facebook"
          flat
          label="Your Facebook Handle"
          outlined
          solo
        ></v-text-field>
        <v-text-field
          v-model="links.website"
          prepend-icon="mdi-chart-tree"
          flat
          label="yoursite.io"
          outlined
          solo
        ></v-text-field>
      <!-- / Links -->

      <!-- / Wallet Address -->
      <v-btn
        class="mr-4"
        type="submit"
        :disabled = "!passValidation"
      >
        Update
      </v-btn>
      <v-btn @click="clear">
        clear
      </v-btn>
    </form>
</template>

<script>
import { mapActions } from 'vuex';
import formValidator from '@/lib/formValidators/formValidator';

export default {
  name: 'ProfileSettingsForm',
  data: () => ({
    username: '',
    email: '',
    bio: '',
    links: {
      twitter: '',
      instagram: '',
      facebook: '',
      website: '',
    },
    errorMessages: {
      usernameMsg: '',
    },
    rules: { // rules for user input
      counter: (value) => value.length <= 30 || 'Max 30 characters',
      spelling: (value) => {
        const regex = /^[\w\d-_]*$/g;
        return regex.test(value) || 'Invalid username';
      },
      checkEmail: formValidator.validateEmail,
    },
  }),
  computed: {
    passValidation() { // check validation
      let pass = (this.username !== ''
      || this.email !== ''
      || this.bio !== ''
      || this.links.twitter !== ''
      || this.links.instagram !== ''
      || this.links.facebook !== ''
      || this.links.website !== '');
      console.log(pass);
      if (this.username) {
        pass = pass && typeof this.rules.counter(this.username) === 'boolean';
        pass = pass && typeof this.rules.spelling(this.username) === 'boolean';
      }
      if (this.email) pass = pass && typeof this.rules.checkEmail(this.email) === 'boolean';
      return pass;
    },
  },
  watch: {
    username(newVal) {
      if (newVal === null) {
        this.username = '';
      }
    },
    email(newVal) {
      if (newVal === null) {
        this.email = '';
      }
    },
  },
  methods: {
    ...mapActions({ updateUser: 'user/updateUser' }),
    submit() {
      console.log('submit');
      const format = {
        username: this.username,
        email: this.email,
        biography: this.bio,
        twitter: this.links.twitter,
        instagram: this.links.instagram,
        website: this.links.website,
        facebook: this.links.facebook,
      };
      const keys = Object.keys(format);
      for (let i = 0; i < keys.length; i += 1) {
        if (!format[keys[i]]) delete format[keys[i]];
      }
      this.updateUser({ fields: format }).then((data) => {
        console.log(data);
        // redirect on success
        this.$router.push({ name: 'My Profile Dashboard' });
      }).catch((err) => {
        if (err.response) {
          if (err.response.status === 409) {
            this.errorMessages.usernameMsg = 'Username already exists';
          } else if (err.response.status === 401) {
            // invalid data need to sign off
            console.log('logging off');
            // log off
          }
        }
        console.log(err);
      });
      // need to check this.$router.push({ name: 'My Profile Dashboard' });
    },
    clear() { // need to reset state
      this.username = '';
      this.email = '';
      this.bio = '';
      this.links.twitter = '';
      this.links.instagram = '';
      this.links.facebook = '';
      this.links.website = '';
    },
    handleClear() {
      this.username = '';
    },
    onType() {
      this.errorMessages.usernameMsg = '';
    },
  },
};
</script>
