<template>
  <section class="marketplace">
    <section class="banner-image pa-5">
      <v-img
        :aspect-ratio="16/9"
        class="banner-image__background"
        cover
        height="500"
        :src="collection.banner"
        width="100%"
      />
      <v-btn v-if="isOwner" outlined color="white" @click='handleBannerFile'>
        <v-icon large>mdi-arrow-up-bold-box-outline</v-icon>
      </v-btn>
      <input
        v-if="isOwner"
        ref = 'uploaderBanner'
        v-show = 'false'
        type='file'
        @change='onFileChangedBackground'
      >
    </section>
    <section class="text-center mt-n16 mb-10 px-5">
      <v-avatar
        class="mb-5"
        size="150"
          @mouseover="logoOverlay = true"
          @mouseleave="logoOverlay = false"
          @click='handleLogoFile'
      >
        <v-overlay
          v-if="isOwner"
          :absolute="true"
          :value="logoOverlay"
        >
        <input
        v-if="isOwner"
        ref = 'uploader'
        v-show = 'false'
        type='file'
        @change='onFileChangedLogo'
        >
        <v-icon large>mdi-arrow-up-bold-box-outline</v-icon>
      </v-overlay>
        <v-img :src="collection.logo" />
      </v-avatar>
      <h1 class="text-h3 font-weight-bold mb-3">
        {{collection.collectionName}}
      </h1>
      <!-- replace the entire thing with username link-->
      <p class="text--secondary">
          Created by
          <router-link :to='profileLink'>{{collection.creator.username}}</router-link>
        </p>

      <!-- eslint-disable max-len -->
      <v-sheet
        class="mw-100 mx-auto mb-3 d-flex flex-column flex-md-row flex-wrap align-center text-center rounded-lg"
        outlined
        width="500"
      >
        <section class="text-center flex-grow-1 pa-5">
          <p class="font-weight-bold mb-1 text-h5">
          <!-- replace the entire thing with actual collection count -->
            {{collection.count}}
          </p>
          <p class="mb-0 text--secondary body-2">items</p>
        </section>
        <v-divider :vertical="$vuetify.breakpoint.mdAndUp" width="100%" />
      </v-sheet>

      <v-sheet
        class="mx-auto mw-100"
        width="700"
      >
      <!-- replace with project description -->
        <p class="text--secondary mb-0">
         {{collection.description}}
        </p>
      </v-sheet>
    </section>
      <v-container>
        <v-row>
          <v-col
            v-for="(nft, x) in nfts.slice(0, nfts.length)"
            :key="`profile-owned-nft-${x}`"
            cols="12"
            lg="4"
            md="6"
            xl="3"
          >
            <nft-card v-bind="convert(nft)" :image-height="300" />
          </v-col>
        </v-row>
      </v-container>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import BigNumber from 'bignumber.js';
import axios from 'axios';
import collectionAPI from '@/api/collection';

export default {
  name: 'CollectionPage',
  props: {
    collectionID: {
      type: [String, Number],
      required: true,
      default: () => (''),
    },
  },
  components: {
  },
  data: () => ({
    tab: null,
    logoOverlay: false,
    activityFilters: {},
    itemsFilters: {},
    size: 'large',
    loading: true,
    nfts: [],
    collection: {},
  }),
  computed: {
    ...mapGetters({
      user: 'user/profile',
      token: 'user/token',
    }),
    isOwner() {
      return this.user.userID === this.collection.creator.publicID;
    },
    profileLink() {
      return `/profile/${this.collection.creator.publicID}`;
    },
  },
  mounted() {
    const that = this;
    this.init(); // load data
    setTimeout(() => {
      that.loading = false;
    }, 2500);
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.init(); // initialized value
    },
  },
  methods: {
    init() {
      // initialize page
      console.log(this.token);
      collectionAPI.getCollection(this.collectionID).then((data) => {
        this.collection = data;
      });
      collectionAPI.getCollectionItems(this.collectionID).then((data) => {
        this.nfts = data;
      });
    },
    handleLogoFile() {
      this.$refs.uploader.click();
    },
    handleBannerFile() {
      this.$refs.uploaderBanner.click();
    },
    async onFileChangedLogo(e) {
      console.log(this.token);
      const file = e.target.files[0];
      const newUrl = await this.uploadFile(file, 'logo', this.token, this.collectionID);
      if (newUrl) this.collection.logo = newUrl;
    },
    async onFileChangedBackground(e) {
      const file = e.target.files[0];
      const newUrl = await this.uploadFile(file, 'banner', this.token, this.collectionID);
      if (newUrl) this.collection.banner = newUrl;
    },
    uploadFile: (file, imageType, token, id) => {
      const url = `/api/collection/uploadImage/${id}?imageType=${imageType}`;
      const formData = new FormData();
      formData.append('uploadImage', file);
      const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      };
      return axios.post(url, formData, { headers }).then((res) => {
        // should send us the new filename
        console.log(res);
        return res.data.newURL;
        // return 'done';
      }).catch((err) => {
        console.log(err);
      });
    },
    convert(nft) {
      let calculatedVal = BigNumber(nft.value);
      calculatedVal = calculatedVal.div(1000000000); // convert to eth
      const simplifyCostFormat = new Intl.NumberFormat(undefined, {
        notation: 'compact',
      });
      return {
        publicID: nft.publicID,
        contentType: nft.contentType,
        contentURL: nft.fileURL,
        mimeType: nft.mimeType,
        itemName: nft.itemName,
        likeCount: nft.likes,
        liked: nft.liked,
        onSale: nft.onSale,
        previewURL: nft.thumbnailURL, // will need to fix this part in order to be more consistent
        symbol: nft.currency,
        contractAddress: nft.contractAddress,
        value: simplifyCostFormat.format(calculatedVal).toString(),
      };
    },
  },
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/variables';

.marketplace-header__sorting {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 15px;
  align-content: stretch;
  width: 100%;
  max-width: 100%;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: 1fr 1fr auto;
    justify-content: flex-end;
    align-content: flex-end;
    width: 500px;
    margin-left: auto;
    max-width: 100%;

    .sort-select {
      grid-row: 1;
      grid-column-start: 2;
    }
  }

  .v-btn-toggle .v-btn {
    height: 100% !important;
  }
}

.results-count {
  padding-right: 40px;
  min-width: 150px;
}

.nft-skeleton-loader {
  .v-skeleton-loader__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.banner-image {
  position: relative;
  display: flex;
  height: 500px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    justify-content: start;
  }

  &__background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__content {
    z-index: 1;
    margin-top: auto;
  }
}

.assets-grid {
  display: grid;
  grid-template-columns: 1fr;
  border-top: 1px solid #efefef;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: minmax(0, 340px) minmax(0, 1fr);
  }

  &-main {
    padding: 28px;
  }
}
</style>
