<template>
  <div>
    <div class="accent">
      <v-container class="py-0">
        <v-row class="my-0">
          <v-col cols="12" class="py-0">
            <div class="d-flex align-center" elevation="0" color="transparent">
              <v-btn
                icon
                left
                @click="() => $router.go(-1)"
              >
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-list-item two-line class="mb-0">
                <v-list-item-avatar class="my-0">
                  <v-img src="https://picsum.photos/id/11/4000/500" />
                </v-list-item-avatar>
                <v-list-item-content class="py-0">
                  <v-list-item-title class="mb-0 pb-0">Official Peace Collection</v-list-item-title>
                  <v-list-item-subtitle class="mt-0">In space there is peace</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div>
      <v-divider class="mb-3"/>
      <v-container>
        <v-row>
          <v-col cols="12" md="7">
            <section class="d-flex justify-space-between">
              <p class="subtitle-2 text--secondary font-weight-bold mr-3">Type</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-on="on"
                    v-bind="attrs"
                    color="secondary"
                  >
                    <v-icon color="secondary">mdi-information-outline</v-icon>
                  </v-btn>
                </template>

                <span>Lorem Ipsum Dolor Amet</span>
              </v-tooltip>
            </section>
            <v-sheet class="d-flex w-100 flex-row mb-8 type-buttons" outlined rounded>
              <v-btn
                large
                class="white text--secondary vertical-icon-button flex-grow-1"
                height="150"
                :class="{
                  'accent': price === 'fixed'
                }"
                elevation="0"
                @click="price = 'fixed'"
              >
                <v-icon class="fill-width mb-3">mdi-currency-usd</v-icon>
                <p class="mb-0">Fixed Price</p>
              </v-btn>
              <v-divider vertical height="100%" />
              <v-btn
                large
                class="white text--secondary vertical-icon-button flex-grow-1"
                height="150"
                :class="{
                  'accent': price === 'timed'
                }"
                elevation="0"
                @click="price = 'timed'"
              >
                <v-icon class="fill-width mb-3">mdi-clock-outline</v-icon>
                <p class="mb-0">Timed Auction</p>
              </v-btn>
            </v-sheet>

            <section class="d-flex justify-space-between">
              <p class="subtitle-2 text--secondary font-weight-bold mr-3">Price</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-on="on"
                    v-bind="attrs"
                    color="secondary"
                  >
                    <v-icon color="secondary">mdi-information-outline</v-icon>
                  </v-btn>
                </template>

                <span>Lorem Ipsum Dolor Amet</span>
              </v-tooltip>
            </section>
            <section class="d-flex">
              <v-sheet width="200" class="mr-3">
                <v-select
                  value="Eth"
                  :items="['Eth']"
                  :menu-props="{ bottom: true, offsetY: true }"
                  filled
                  label="currency"
                  solo
                  flat
                  outlined
                  hide-details
                  class="accent items-select font-weight-bold mb-4"
                >
                  <template v-slot:append>
                    <v-icon class="selections-dropdown-icon">
                      mdi-chevron-down
                    </v-icon>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-icon left>$ethereumIcon</v-icon>
                    {{ item }}
                  </template>
                </v-select>
              </v-sheet>

              <v-text-field
                v-model.number="price"
                hide-details
                solo
                flat
                single-line
                outlined
                type="number"
                class="disable-prepend-inner-margin text-left"
                label="Amount"
                placeholder="0"
              />
            </section>
            <section class="d-flex justify-space-between">
              <p class="subtitle-2 text--secondary font-weight-bold mr-3">Duration</p>
            </section>
            <section class="d-flex justify-space-between align-center">
              <p class="mb-0 text--secondary font-weight-bold">Sell as a bundle</p>
              <v-switch large inset />
            </section>
            <section class="d-flex justify-space-between align-center">
              <p class="mb-0 text--secondary font-weight-bold">Reserve for specific buyer.</p>
              <v-switch large inset />
            </section>
            <v-divider class="mb-4" />
            <section class="d-flex justify-space-between">
              <p class="subtitle-2 text--secondary font-weight-bold mr-3">Fees</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-on="on"
                    v-bind="attrs"
                    color="secondary"
                  >
                    <v-icon color="secondary">mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                <span>Lorem Ipsum Dolor Amet</span>
              </v-tooltip>
            </section>
            <v-list>
              <v-list-item>
                <v-list-item-subtitle class="font-weight-bold">Service Fee</v-list-item-subtitle>
                <span class="body-2 text--secondary">
                  2.5%
                </span>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle class="font-weight-bold">
                  Creator Royalty
                </v-list-item-subtitle>
                <span class="body-2 text--secondary">
                  10.0%
                </span>
              </v-list-item>
            </v-list>

            <v-btn
              color="primary"
              @click="$router.go(-1)"
            >
              Complete Listing
            </v-btn>
          </v-col>
          <v-col cols="12" md="5">
            <collection-card v-bind="featuredCollection" class="mt-12 mt-md-0" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    collectionID: {
      type: [String, Number],
      required: true,
      default: () => (''),
    },
  },
  data: () => ({
    type: 'fixed-price',
    priceCurrency: 'Eth',
    price: null,
    duration: 'six-months',
    sellAsBundle: false,
    reserve: false,
  }),
  computed: {
    ...mapGetters({
      collections: 'collections/all',
    }),
    featuredCollection() {
      return this.collections.slice(0, 1).shift();
    },
  },
};
</script>

<style lang="scss">
.type-buttons .v-btn:not(:last-of-type) {
  border-right: 2px solid #000;
  //rgba(0, 0, 0, 0.12);
}
</style>
