<template>
  <v-btn-toggle rounded>
    <v-btn to="/myProfile/settings" icon>
      <v-icon>mdi-cog</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'ProfileDashboardActions',
  props: {},
};
</script>
