<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h3 mb-10">
          Create New item
        </h1>

        <create-nft-form />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CreateNFTForm from '@/features/forms/CreateNFTForm.vue';

export default {
  name: 'CreateNFT',
  components: {
    'create-nft-form': CreateNFTForm,
  },
};
</script>
