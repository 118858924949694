import axios from 'axios';

export default {
  login: async ({ commit }) => {
    try {
      // set store false
      // eslint-disable-next-line
      if (!window.ethereum) window.alert('No Metamask Wallet Detected');// TODO::Redirects user to appropriate metamask account
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      console.log(accounts);
      const account = accounts[0];
      axios.get(`/api/user/nonce?address=${account}`).then(async (response) => {
        // get response if wallet address is valid one
        console.log(response);
        console.log(account);
        const message = `${response.data.message}\n\nAddress: ${account}\n\nNonce: ${response.data.nonce}`;
        // message details full
        const params = [account, message];
        const sig = await window.ethereum.request({ method: 'personal_sign', params });
        // sign data
        console.log('outputing sig:');
        console.log(sig);
        const data = {
          address: account,
          signature: sig,
        };
        // send login informationa and save data here
        axios.post('/api/user/login', data).then((res) => {
          // information to localhost
          console.log(Buffer.from(res.data.token, 'base64').toString('ascii'));
          commit('SET_LOGIN', res.data);
        });
      }).catch((e) => {
        console.log(e);
      });
    } catch (e) {
      console.log(e);
    }
  },
  getUser: async ({ commit }) => { // get current user
    const authToken = localStorage.getItem('token');
    console.log('this is the token');
    console.log(authToken);
    const options = {
      headers: { Authorization: `Bearer ${authToken}` },
    };
    return axios.get('/api/user/checkAuth', options).then((res) => {
      // user maintain their data right here
      const payload = { token: authToken, user: res.data };
      commit('SET_PROFILE', payload);
      return 'done';
    }).catch((err) => {
      if (err.response && err.response.status === 401) {
        commit('LOG_OFF');
      }
      throw err;
    });
  },
  uploadFile: ({ commit, state }, { file, imageType }) => {
    const url = `/api/user/uploadImage?imageType=${imageType}`;
    const formData = new FormData();
    formData.append('uploadImage', file);
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${state.authToken}`,
    };
    return axios.post(url, formData, { headers }).then((res) => {
      // should send us the new filename
      console.log(res);
      const payload = { newURL: res.data.newURL, type: imageType };
      commit('SET_IMAGE', payload);
      return 'done';
    }).catch((err) => {
      console.log(err);
      if (err.response && err.response.status === 401) {
        commit('LOG_OFF');
      }
      throw err;
    });
  },
  updateUser: ({ commit, state }, { fields }) => {
    const headers = {
      Authorization: `Bearer ${state.authToken}`,
    };
    console.log(fields);
    // returns a promise for page to handle
    return axios.patch('/api/user/update', fields, { headers }).then((res) => {
      console.log(res);
      commit('SET_DATA', res.data);
      return res.data;
    });
  },
  saveRefCode: ({ commit, state }, { fields }) => {
    const headers = {
      Authorization: `Bearer ${state.authToken}`,
    };
    console.log(fields);
    // returns a promise for page to handle
    return axios.post('/api/user/saveRefCode', fields, { headers }).then((res) => {
      console.log(res);
      commit('SET_DATA', res.data);
      return res.data;
    });
  },
};
