// import axios from 'axios';
import { ethers } from 'ethers';
import abi from '../abi/abi';

// api handling items
export default {
  approveSale: async (tokenID) => {
    try {
      if (!tokenID || tokenID === 'unminted') {
        return null;
      }
      const account = '0x508c7fcEA9CDB4A4bCD8707f34564CfE036102A6';
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // check owner of token
      const signer = provider.getSigner();
      const contract = new ethers.Contract('0x6D273a7aEB0d204DB473127257Fd1C05001c51af', abi.abi, signer);
      // check if unminted
      console.log('approve', tokenID);
      const receipt = await contract.approve(account, tokenID);
      // await for receipt to finish
      console.log(receipt);
      // change delay to receipt
      const result = await receipt.wait();
      return result;
    } catch (e) {
      console.log(e);
      // throw error at user
      throw e;
    }
  }, // chekc chain
  checkStatus: async (tokenID) => {
    console.log(tokenID);
  },
};
