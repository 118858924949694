var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-h4 text-center font-weight-bold mb-4"},[_vm._v("Top NFTs")]),_c('p',{staticClass:"text-center mb-5 text--secondary"},[_vm._v("The top NFTs on OpenSea, ranked by volume, floor price and other statistics. ")])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"10","xl":"4"}},[_c('section',{staticClass:"d-sm-flex align-center justify-center align-center filters-wrap"},[_c('v-select',{staticClass:"d-inline-flex primary--text selections-dropdown font-weight-bold",attrs:{"items":_vm.timeframeOptions,"menu-props":{ bottom: true, offsetY: true },"color":"primary","flat":"","outlined":"","solo":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"selections-dropdown-icon",attrs:{"color":"primary"}},[_vm._v(" mdi-chevron-down ")])]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.timeframe),callback:function ($$v) {_vm.timeframe=$$v},expression:"timeframe"}}),_c('v-select',{staticClass:"d-inline-flex primary--text selections-dropdown font-weight-bold",attrs:{"items":_vm.categoryOptions,"menu-props":{ bottom: true, offsetY: true },"color":"primary","flat":"","outlined":"","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v("mdi-grid ")]),_c('span',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"append",fn:function(){return [_c('v-icon',{staticClass:"selections-dropdown-icon",attrs:{"color":"primary"}},[_vm._v(" mdi-chevron-down ")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-grid")]),_vm._v(" "+_vm._s(item.text)+" ")]}}]),model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('v-select',{staticClass:"d-inline-flex primary--text selections-dropdown font-weight-bold",attrs:{"items":_vm.chainOptions,"menu-props":{ bottom: true, offsetY: true },"color":"primary","flat":"","outlined":"","solo":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"selections-dropdown-icon",attrs:{"color":"primary"}},[_vm._v(" mdi-chevron-down ")])]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v("$ethereumIcon ")]),_c('span',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v("$ethereumIcon")]),_vm._v(" "+_vm._s(item.text)+" ")]}}]),model:{value:(_vm.chain),callback:function ($$v) {_vm.chain=$$v},expression:"chain"}})],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"transparent",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableItems,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.collection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('section',{staticClass:"d-flex align-center justify-start py-3"},[_c('span',{staticClass:"font-weight-bold subtitle-2"},[_vm._v(_vm._s(index))]),_c('v-avatar',{staticClass:"mx-3",attrs:{"size":"30"}},[_c('v-img',{attrs:{"src":item.image}})],1),(item.verified)?_c('v-icon',{staticClass:"ml-n5 mt-auto mr-1",attrs:{"color":"primary","small":""}},[_vm._v(" mdi-check-decagram ")]):_vm._e(),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.collection))])],1)]}},{key:"item.volume",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" $ethereumIcon ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.volume)+" ")])]}},{key:"item.twenty_four_hours",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{staticClass:"font-weight-bold",class:index % 2 === 0 ? 'green--text' : 'red--text'},[_vm._v(" "+_vm._s((index % 2 === 0) ? '+' : '-')+" "+_vm._s(item.twenty_four_hours)+"% ")])]}},{key:"item.seven_days",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{staticClass:"font-weight-bold",class:index % 2 === 0 ? 'green--text' : 'red--text'},[_vm._v(" "+_vm._s((index % 2 === 0) ? '+' : '-')+" "+_vm._s(item.seven_days)+"% ")])]}},{key:"item.floor_price",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v(" $ethereumIcon ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.floor_price))])]}},{key:"item.owners",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.owners))])]}},{key:"item.items",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.items))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }