import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ethereumIcon from '../features/icon-components/EthereumIcon.vue';
import xpdIcon from '../features/icon-components/XPDIcon.vue';

Vue.use(Vuetify);
Vue.component('ethereum-icon', ethereumIcon);
Vue.component('xpd-icon', xpdIcon);

export default new Vuetify({
  icons: {
    values: {
      ethereumIcon: {
        component: 'ethereum-icon',
        props: {},
      },
      xpdIcon: {
        component: 'xpd-icon',
        props: {},
      },
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        yellow: '#F1CD3B',
        accent: '#fbfdff',
      },
    },
  },
});
