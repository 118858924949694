<template>
  <section class="profile-quickview mx-auto d-md-flex">
    <v-avatar size="100" class="mb-3 mr-md-4 mb-md-0" @mouseover="overlay = true"
      @mouseleave="overlay = false"
      @click='handleFile'>
      <v-overlay v-if="isUser" :absolute="absolute" :value="overlay">
        <v-icon large>mdi-arrow-up-bold-box-outline</v-icon>
      </v-overlay>
      <v-img :src="profile.avatar">
      </v-img>
      <input v-if="isUser" ref='uploader' v-show='false' type='file' @change='onFileChanged'>
    </v-avatar>
    <section>
      <!-- need to implement uploading file-->
      <p class="text-h3 mb-2 white--text font-weight-bold">{{ profile.username }}</p>
      <p class="subtitle-1 mb-1 white--text">Your Public Address: {{ profile.publicAddress }}</p>
      <p class="subtitle-1 mb-1 white--text">Referral to: {{ profile.refCode }} {{ displayRefCode }}
        <input v-model="newRefCode"
               v-show=checkCurrentRef
               :disabled=false
               :class="['eth-address-input', addressInputClass]"
               placeholder="Place the referral code here"
               @input="checkValidAddress" /> </p>
      <p class="text-danger" v-if="!isValidAddress">{{ addressErrorMessage }}</p>
      <v-btn color="primary" large v-show=checkCurrentRef class="font-weight-bold text-capitalize"
        v-on:click="addRefCode()" :disabled=!isValidAddress>Add Referral Address</v-btn>
      <p class="subtitle-2 mb-0 white--text">{{ emailData }}</p>
    </section>
  </section>
</template>
<style scoped>
/* Layout Styles */
.profile-quickview {
  padding: 20px;
  /* background-color: #333; Dark background for the profile quickview */
  border-radius: 10px;
}

/* Typography Styles */
.text-h3 {
  font-size: 1.5rem;
}

.subtitle-1 {
  font-size: 1.2rem;
}

.subtitle-2 {
  font-size: 1rem;
}

.text-danger {
  font-size: 0.8rem;
  margin-top: 5px;
  color: #ff4b5c; /* Slightly more noticeable red */
}

/* Form Controls and Feedback */
.eth-address-input {
  width: 400px;
  padding-left: 1rem;
}

.eth-address-invalid {
  border-color: #ff4b5c; /* Slightly more noticeable red */
}

.eth-address-valid {
  border-color: #4caf50; /* Green color for valid input */
}
p input{
  background-color: rgba(160, 160, 50, 0.8);
  border: 3px solid rgba(255, 160, 0, 1);
  border-radius: 5px;
  color: white;
}
::placeholder {
    font-style: italic;
    color: aquamarine;
  }
</style>
<script>
import { mapGetters, mapActions } from 'vuex';
import { utils } from 'ethers';

export default {
  name: 'ProfileQuickview',
  data() {
    return {
      overlay: false,
      absolute: true,
      newRefCode: this.newRefCode, // Initialize localRefCode with the prop's value
      isValidAddress: true,
      addressErrorMessage: '',
    };
  },
  props: {
    isUser: {
      type: Boolean,
      required: true,
    },
    profile: { // profile object
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({ uploadAvatar: 'user/uploadFile', alert: 'alert/callErrorMessage', saveRefCode: 'user/saveRefCode' }), // uploading avatar
    handleFile() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      // call map action to read file from here
      console.log('file changed');
      const file = e.target.files[0];
      this.uploadAvatar({ file, imageType: 'avatar' }).then(() => {
        this.profile.avatar = this.user.thumbnailImageURL;
      }).catch((err) => {
        console.log(err); // check error type
        this.alert('Invalid File type.');
      });
      console.log(file);
    },
    clickedAddRef() {
      console.log(` typing ${this.newRefCode}`);
    },
    addRefCode() {
      console.log('Save RefCode');
      const format = {
        refCode: this.newRefCode,
      };
      const keys = Object.keys(format);
      for (let i = 0; i < keys.length; i += 1) {
        if (!format[keys[i]]) delete format[keys[i]];
      }
      this.saveRefCode({ fields: format }).then((data) => {
        console.log(data);
        // redirect on success
        window.location.reload();
      }).catch((err) => {
        if (err.response) {
          if (err.response.status === 409) {
            this.errorMessages.usernameMsg = 'error';
          } else if (err.response.status === 401) {
            // invalid data need to sign off
            console.log('logging off');
            // log off
          }
        }
        console.log(err);
      });
      // need to check this.$router.push({ name: 'My Profile Dashboard' });
    },
    checkValidAddress() {
      if (!utils.isAddress(this.newRefCode)) {
        this.isValidAddress = false;
        this.addressErrorMessage = 'Invalid Ethereum address.';
      } else {
        this.isValidAddress = true;
        this.addressErrorMessage = '';
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'user/profile',
    }),
    emailData() {
      if (this.profile.email) {
        return `Email: ${this.profile.email}`;
      }
      return '';
    },
    checkCurrentRef() {
      if (this.profile.refCode) {
        return false;
      }
      return true;
    },
    displayRefCode() {
      if (this.profile.refCode) {
        return '';
      }
      return ' No Referral Code';
    },
    addressInputClass() {
      return {
        'is-invalid': !this.isValidAddress,
        'is-valid': this.isValidAddress && this.newRefCode,
      };
    },
  },
};
</script>
