/*
import fakeData from '@/lib/fake-data/nfts.json';

export default {
  namespaced: true,
  state: {
    data: fakeData,
  },
  getters: {
    all: (state) => state.data.slice(0, 30),
  },
};
*/
import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
