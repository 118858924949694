<template>
  <v-container fluid class="py-0 px-8">
    <v-row>
      <v-col cols="12" md="8" lg="9" xl="10">
        <section class="py-10">
          <h1 class="text-h2 mb-8">Profile Settings</h1>
          <profile-settings-form />
        </section>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProfileSettingsForm from '@/features/forms/ProfileSettingsForm.vue';

export default {
  name: 'ProfileSettings',
  components: {
    ProfileSettingsForm,
  },
  methods: {
    updateView(payload) {
      this.activeView = payload;
    },
  },
};
</script>
