import Vue from 'vue';

/**
 * Async imports
 * @see https://vuejs.org/v2/guide/components-dynamic-async.html
 * @why Divide the app into smaller chunks and only load a component from the server when it’s
 * needed.
 */

// Features
Vue.component('nft-card', () => import('@/features/nfts/nft-card/NFTCard.vue'));
Vue.component('input-label', () => import('@/features/inputs/InputLabel.vue'));
Vue.component('input-prepended-currency', () => import('@/features/inputs/InputPrependedCurrency.vue'));
Vue.component('page-header', () => import('@/features/page-structure/PageHeader.vue'));
Vue.component('hero-section', () => import('@/features/hero-section/HeroSection.vue'));
Vue.component('collection-card', () => import('@/features/collection-card/CollectionCard.vue'));
Vue.component('activity-table', () => import('@/features/activity-table/ActivityTable.vue'));
Vue.component('nft-list', () => import('@/features/nfts/nft-list/NFTList.vue'));
Vue.component('collections-list', () => import('@/features/collections-list/CollectionsList.vue'));
Vue.component('information-panel', () => import('@/features/information-panel/InformationPanel.vue'));
Vue.component('buttons-container', () => import('@/features/buttons/ButtonsContainer.vue'));
Vue.component('favourite-button', () => import('@/features/buttons/FavouriteButton.vue'));
Vue.component('application-logo', () => import('@/features/generic/ApplicationLogo.vue'));
