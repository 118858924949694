export default {
  SET_IS_AUTHENTICATED(state, payload) { // check isAuthenticated status
    state.isAuthenticated = true;
    state.authToken = payload.token;
  },
  SET_LOGIN(state, payload) { // setting state for login
    state.isAuthenticated = true;
    state.authToken = payload.token;
    localStorage.setItem('token', payload.token);
    state.profile.walletAddress = payload.user.publicAddress;
    state.profile.userID = payload.user.publicID;
    state.profile.username = payload.user.username;
    state.profile.thumbnailImageURL = payload.user.avatar;
    state.profile.profileBannerImageURL = payload.user.banner;
    state.profile.bio = payload.user.biography;
    state.profile.email = payload.user.email;
    state.profile.refCode = payload.user.refCode;
    // links
    state.profile.links.twitter = payload.user.twitter;
    state.profile.links.website = payload.user.website;
    state.profile.links.instagram = payload.user.instagram;
    state.profile.links.facebook = payload.user.facebook;
    if (payload.user.likes) {
      state.profile.likes = payload.user.likes;
    } else {
      state.profile.likes = [];
    }
  },
  SET_PROFILE(state, payload) { // setting state after refresh
    state.isAuthenticated = true;
    state.authToken = payload.token;
    state.profile.walletAddress = payload.user.publicAddress;
    state.profile.userID = payload.user.publicID;
    state.profile.username = payload.user.username;
    state.profile.thumbnailImageURL = payload.user.avatar;
    state.profile.profileBannerImageURL = payload.user.banner;
    state.profile.bio = payload.user.biography;
    state.profile.email = payload.user.email;
    state.profile.refCode = payload.user.refCode;
    // links
    state.profile.links.twitter = payload.user.twitter;
    state.profile.links.website = payload.user.website;
    state.profile.links.instagram = payload.user.instagram;
    state.profile.links.facebook = payload.user.facebook;
    if (payload.user.likes) {
      state.profile.likes = payload.user.likes;
    } else {
      state.profile.likes = [];
    }
  },
  SET_DATA(state, payload) { // user set data
    // set data
    state.profile.walletAddress = payload.publicAddress;
    state.profile.userID = payload.publicID;
    state.profile.username = payload.username;
    state.profile.thumbnailImageURL = payload.avatar;
    state.profile.profileBannerImageURL = payload.banner;
    state.profile.bio = payload.biography;
    state.profile.email = payload.email;
    state.profile.refCode = payload.user.refCode;
    // links
    state.profile.links.twitter = payload.twitter;
    state.profile.links.website = payload.website;
    state.profile.links.instagram = payload.instagram;
    state.profile.links.facebook = payload.facebook;
    if (payload && 'likes' in payload) {
      state.profile.likes = payload.user.likes;
    } else {
      state.profile.likes = [];
    }
  },
  SET_IMAGE(state, payload) {
    if (payload.type === 'avatar') {
      state.profile.thumbnailImageURL = payload.newURL;
    } else if (payload.type === 'banner') {
      state.profile.profileBannerImageURL = payload.newURL;
    } else {
      throw new Error('invalid input');
    }
  },
  LIKE_ITEM(state, payload) {
    if (!state.profile.likes.includes(payload.itemID)) {
      console.log('like');
      state.profile.likes.push(payload.itemID);
    }
  },
  UNLIKE_ITEM(state, payload) {
    if (state.profile.likes.includes(payload.itemID)) {
      console.log('unlike');
      state.profile.likes = state.profile.likes.filter((e) => e !== payload.itemID);
    }
  },
  LOG_OFF(state) { // delete local storage
    localStorage.removeItem('token');
    state.isAuthenticated = false;
    state.authToken = '';
    state.profile.walletAddress = '';
    state.profile.userID = '';
    state.profile.username = '';
    state.profile.thumbnailImageURL = 'https://riivr.sgp1.digitaloceanspaces.com/default/user.png';
    state.profile.profileBannerImageURL = 'https://riivr.sgp1.digitaloceanspaces.com/default/user.png';
    state.profile.bio = '';
    state.profile.email = '';
    state.profile.refCode = '';
    // links
    state.profile.links.twitter = '';
    state.profile.links.website = '';
    state.profile.links.instagram = '';
    state.profile.links.facebook = '';
    state.profile.likes.length = 0;
  },
};
