var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"accent"},[_c('v-container',{staticClass:"py-0"},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center",attrs:{"elevation":"0","color":"transparent"}},[_c('v-btn',{attrs:{"icon":"","left":""},on:{"click":function () { return _vm.$router.go(-1); }}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('v-list-item',{staticClass:"mb-0",attrs:{"two-line":""}},[_c('v-list-item-avatar',{staticClass:"my-0"},[_c('v-img',{attrs:{"src":"https://picsum.photos/id/11/4000/500"}})],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"mb-0 pb-0"},[_vm._v("Official Peace Collection")]),_c('v-list-item-subtitle',{staticClass:"mt-0"},[_vm._v("In space there is peace")])],1)],1)],1)])],1)],1)],1),_c('div',[_c('v-divider',{staticClass:"mb-3"}),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('section',{staticClass:"d-flex justify-space-between"},[_c('p',{staticClass:"subtitle-2 text--secondary font-weight-bold mr-3"},[_vm._v("Type")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('span',[_vm._v("Lorem Ipsum Dolor Amet")])])],1),_c('v-sheet',{staticClass:"d-flex w-100 flex-row mb-8 type-buttons",attrs:{"outlined":"","rounded":""}},[_c('v-btn',{staticClass:"white text--secondary vertical-icon-button flex-grow-1",class:{
                'accent': _vm.price === 'fixed'
              },attrs:{"large":"","height":"150","elevation":"0"},on:{"click":function($event){_vm.price = 'fixed'}}},[_c('v-icon',{staticClass:"fill-width mb-3"},[_vm._v("mdi-currency-usd")]),_c('p',{staticClass:"mb-0"},[_vm._v("Fixed Price")])],1),_c('v-divider',{attrs:{"vertical":"","height":"100%"}}),_c('v-btn',{staticClass:"white text--secondary vertical-icon-button flex-grow-1",class:{
                'accent': _vm.price === 'timed'
              },attrs:{"large":"","height":"150","elevation":"0"},on:{"click":function($event){_vm.price = 'timed'}}},[_c('v-icon',{staticClass:"fill-width mb-3"},[_vm._v("mdi-clock-outline")]),_c('p',{staticClass:"mb-0"},[_vm._v("Timed Auction")])],1)],1),_c('section',{staticClass:"d-flex justify-space-between"},[_c('p',{staticClass:"subtitle-2 text--secondary font-weight-bold mr-3"},[_vm._v("Price")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('span',[_vm._v("Lorem Ipsum Dolor Amet")])])],1),_c('section',{staticClass:"d-flex"},[_c('v-sheet',{staticClass:"mr-3",attrs:{"width":"200"}},[_c('v-select',{staticClass:"accent items-select font-weight-bold mb-4",attrs:{"value":"Eth","items":['Eth'],"menu-props":{ bottom: true, offsetY: true },"filled":"","label":"currency","solo":"","flat":"","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"selections-dropdown-icon"},[_vm._v(" mdi-chevron-down ")])]},proxy:true},{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v("$ethereumIcon")]),_vm._v(" "+_vm._s(item)+" ")]}}])})],1),_c('v-text-field',{staticClass:"disable-prepend-inner-margin text-left",attrs:{"hide-details":"","solo":"","flat":"","single-line":"","outlined":"","type":"number","label":"Amount","placeholder":"0"},model:{value:(_vm.price),callback:function ($$v) {_vm.price=_vm._n($$v)},expression:"price"}})],1),_c('section',{staticClass:"d-flex justify-space-between"},[_c('p',{staticClass:"subtitle-2 text--secondary font-weight-bold mr-3"},[_vm._v("Duration")])]),_c('section',{staticClass:"d-flex justify-space-between align-center"},[_c('p',{staticClass:"mb-0 text--secondary font-weight-bold"},[_vm._v("Sell as a bundle")]),_c('v-switch',{attrs:{"large":"","inset":""}})],1),_c('section',{staticClass:"d-flex justify-space-between align-center"},[_c('p',{staticClass:"mb-0 text--secondary font-weight-bold"},[_vm._v("Reserve for specific buyer.")]),_c('v-switch',{attrs:{"large":"","inset":""}})],1),_c('v-divider',{staticClass:"mb-4"}),_c('section',{staticClass:"d-flex justify-space-between"},[_c('p',{staticClass:"subtitle-2 text--secondary font-weight-bold mr-3"},[_vm._v("Fees")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('span',[_vm._v("Lorem Ipsum Dolor Amet")])])],1),_c('v-list',[_c('v-list-item',[_c('v-list-item-subtitle',{staticClass:"font-weight-bold"},[_vm._v("Service Fee")]),_c('span',{staticClass:"body-2 text--secondary"},[_vm._v(" 2.5% ")])],1),_c('v-list-item',[_c('v-list-item-subtitle',{staticClass:"font-weight-bold"},[_vm._v(" Creator Royalty ")]),_c('span',{staticClass:"body-2 text--secondary"},[_vm._v(" 10.0% ")])],1)],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Complete Listing ")])],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('collection-card',_vm._b({staticClass:"mt-12 mt-md-0"},'collection-card',_vm.featuredCollection,false))],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }