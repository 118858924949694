<template>
    <form>
      <input-label>Content Type <span class="red--text">*</span></input-label>
      <!-- contentType -->
        <v-select
          v-model="data.contentType"
          :items="contentOptions"
          data-vv-name="select"
          :rules = "[rules.notEmpty]"
          label="Content Type"
          @change = "clear"
          flat
          solo
          outlined
        />
      <input-label>
        Content <span class="red--text">*</span>
      </input-label>
      <p class="body-2 text--secondary">
        {{supportedType}} Max Size:
        100mb
      </p>
        <v-file-input
          id="file"
          v-model="data.file"
          label="Attach a file"
          truncate-length="15"
          :rules="[
            value => !value || value.size < 1024*1024*100 || 'File Size should be less than 100mb.',
            value => value !== null || 'File Empty'
          ]"
          clearable
          solo
          flat
          outlined
          hide-details="auto"
        />
        <br>
      <input-label v-show="isMusic">
        Audio Poster
      </input-label>
      <p class="body-2 text--secondary" v-show="isMusic">
        File types supported: JPG, PNG, JPEG, GIF.
      </p>
        <v-file-input v-show="isMusic"
          id="file"
          v-model="data.filePreview"
          label="Attach a file"
          truncate-length="15"
          :rules="[
            value => {!value || value.size < (1024*1024*100)
            || 'File Size should be less than 100mb.'},
            value => value !== null || 'File Empty'
          ]"
          clearable
          solo
          flat
          outlined
          hide-details="auto"
        />
        <br>
      <!-- File Upload -->
      <!-- Name -->
      <input-label>
        Item Name <span class="red--text">*</span>
      </input-label>
        <v-text-field
          v-model="data.name"
          label="Item Name"
          :error-messages = "errorMessages.itemNameMsg"
          hint="No spaces or special characters allowed. Use _ or - instead."
          :rules = "[rules.itemNameCount, rules.itemNameSpelling]"
          counter = "30"
          flat
          solo
          outlined
        ></v-text-field>

      <!-- / Name -->
      <!-- / External Link -->
      <input-label>
        Supply <span class="red--text">*</span>
      </input-label>
      <p class="body-2 text--secondary">
        The number of copies that can be minted. No gas cost to you! Quantities above one coming
        soon.
        <v-icon>mdi-information-outline</v-icon>
      </p>
      <!-- Supply -->
        <v-text-field
          v-model="data.supply"
          label="Supply"
          flat
          :rules="[rules.supplyCheck]"
          solo
          type="number"
          outlined
        ></v-text-field>
    <input-label>Tags</input-label>
    <p class="body-2 text--secondary">
        Tags to help users search for your NFTs.
      </p>
        <v-combobox
          v-model="chips"
          chips
          clearable
          label="Type your keyword and Press Enter to add a new tag."
          hint="Type your keyword and Press Enter to add a new tag."
          multiple
          :rules="[]"
          solo
        >
          <template v-slot:selection="{ attrs, item }">
            <v-chip
              v-bind="attrs"
              close
              @click:close="remove(item)"
            >
            <strong>{{ item }}</strong>&nbsp;
            </v-chip>
          </template>
        </v-combobox>
      <!-- / Supply -->
      <input-label>Blockchain <span class="red--text">*</span></input-label>
      <!-- Blockchain -->
        <v-select
          v-model="data.blockChain"
          :items="blockChainOptions"
          data-vv-name="select"
          :rules = "[rules.notEmpty]"
          label="Blockchain"
          flat
          solo
          outlined
        />

      <!-- / Blockchain -->
        <input-label>Currency <span class="red--text">*</span></input-label>
      <!-- Blockchain -->
        <v-select
          v-model="data.currency"
          :items="currencyOptions"
          data-vv-name="select"
          :rules = "[rules.notEmpty]"
          label="Currency"
          flat
          solo
          outlined
        />
       <input-label>
        Price <span class="red--text">*</span>
      </input-label>
        <v-text-field
          v-model="data.value"
          label="value"
          placeholder="0.3"
          :rules = "[rules.notNegative]"
          :prefix="currency"
          flat
          solo
          outlined
          type ="number"
        ></v-text-field>
      <input-label>
        Description <span class="red--text">*</span>
      </input-label>
      <p class="body-2 text--secondary">
        The description will be included on the item's detail
      page underneath its image.
      </p>
        <v-textarea
          v-model="data.description"
          name="input-7-1"
          label="Description"
          :rules = "[rules.notEmpty]"
          hint=""
          flat
          solo
          outlined
        ></v-textarea>
        <input-label>
        About <span class="red--text">*</span>
      </input-label>
      <p class="body-2 text--secondary">
        Tell us more about your project.
      </p>
        <v-textarea
          v-model="data.about"
          name="input-7-1"
          :rules = "[rules.notEmpty]"
          label="About"
          hint=""
          flat
          solo
          outlined
        ></v-textarea>

      <input-label>
        Collection <span class="red--text">*</span>
      </input-label>
      <p class="body-2 text--secondary">
        This is the collection where your item will appear. <v-icon>mdi-info-outline</v-icon>
      </p>
        <v-select
          v-model="data.collection"
          :items="collectionOptions"
          :rules = "[rules.notEmpty]"
          data-vv-name="select"
          label="Collection"
          flat
          solo
          outlined
        ></v-select>

      <v-btn
        class="mr-4"
        :disabled="!passValidation"
        @click="submit"
      >
        submit
      </v-btn>
      <v-btn @click="clear">
        clear
      </v-btn>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BigNumber from 'bignumber.js';
import itemAPI from '@/api/item';
import collectionAPI from '@/api/collection';

export default {
  name: 'CreateNFTForm',
  data: () => ({
    chips: [],
    snackbar: false,
    overlay: false,
    data: {
      contentType: 'Art',
      file: null,
      filePreview: null,
      name: '',
      value: '',
      supply: '',
      collection: '',
      currency: '',
      blockChain: '',
      description: '',
      about: '',
    },
    blockChainOptions: [
      {
        text: 'BNB Smart Chain',
        value: 'binance',
      },
    ],
    currencyOptions: [
      {
        text: 'Podduang (XPD)',
        value: 'xpd',
      },
    ],
    collectionOptions: [ // use api to get the all the collections
      {
        text: 'New Collection',
        value: 'new_collection',
      },
    ],
    contentOptions: [
      {
        text: 'Art',
        value: 'Art',
      },
      {
        text: 'Music',
        value: 'Music',
      },
      {
        text: 'Video',
        value: 'Video',
      },
    ],
    rules: { // input rules
      itemNameCount: (value) => value.length <= 30 || 'Max 30 characters',
      itemNameSpelling: (value) => {
        const regex = /^[\w\d-_]+$/g;
        return regex.test(value) || 'Invalid username';
      },
      notNegative: (value) => { // check if value is number
        const val = BigNumber(value);
        return (val && val.isGreaterThanOrEqualTo(0)) || 'Invalid Value';
      },
      supplyCheck: (value) => value === '1' || 'Supply temporarily restricted to 1',
      notEmpty: (value) => value !== '' || 'Field cannot be empty',
    },
    errorMessages: { // for adding error messages to each field
      itemNameMsg: '',
    },
  }),
  computed: {
    ...mapGetters({ token: 'user/token', profile: 'user/profile' }),
    currency() {
      let currency = '';
      if (this.data.currency) {
        currency = this.data.currency.toUpperCase();
      }
      return currency;
    },
    isMusic() {
      return this.data.contentType === 'Music';
    },
    supportedType() {
      if (this.data.contentType === 'Art') {
        return 'File types supported: jpg, png, jpeg, gif.';
      }
      if (this.data.contentType === 'Music') {
        return 'File types supported: mp3, wav, audio/webm.';
      }
      if (this.data.contentType === 'Video') {
        return 'File types supported: mp4, mpeg, video/webm.';
      }
      return '';
    },
    passValidation() {
      let pass = (this.data.file !== 'null'
      || this.data.name !== ''
      || this.data.value !== ''
      || this.data.supply !== ''
      || this.data.collection !== ''
      || this.data.blockChain !== ''
      || this.data.description !== ''
      || this.data.about !== ''); // not elegant but hey it works
      pass = pass && typeof this.rules.itemNameCount(this.data.name) === 'boolean';
      pass = pass && typeof this.rules.itemNameSpelling(this.data.name) === 'boolean';
      pass = pass && typeof this.rules.supplyCheck(this.data.supply) === 'boolean';
      pass = pass && typeof this.rules.notEmpty(this.data.collection) === 'boolean';
      pass = pass && typeof this.rules.notEmpty(this.data.blockChain) === 'boolean';
      pass = pass && typeof this.rules.notEmpty(this.data.description) === 'boolean';
      pass = pass && typeof this.rules.notEmpty(this.data.about) === 'boolean';
      return pass;
    },
  },
  methods: {
    ...mapActions({ getNFTs: 'nfts/getNFTS', alert: 'alert/callErrorMessage' }),
    // submit and redirects user
    submit() {
      console.log('creating nft');
      let calculatedVal = BigNumber(this.data.value);
      calculatedVal = calculatedVal.multipliedBy(1000000000); // convert to form
      // todo::stringify search input
      const formData = new FormData();
      formData.append('uploadFile', this.data.file);
      if (this.data.contentType === 'Music') {
        formData.append('filePreview', this.data.filePreview);
      }
      formData.append('itemName', this.data.name);
      formData.append('value', calculatedVal.toString());
      formData.append('description', this.data.description);
      formData.append('about', this.data.about);
      formData.append('tags', JSON.stringify(this.chips));
      formData.append('supply', this.data.supply);
      formData.append('blockchain', this.data.blockChain);
      formData.append('currency', this.data.currency);
      this.overlay = true;
      // sending data to backend
      console.log(this.token);
      itemAPI.addItem(formData, this.token,
        this.data.contentType, this.data.collection).then((res) => {
        console.log(res);
        this.overlay = false;
        this.getNFTs();
        this.$router.push({ name: 'Assets' });
        // redirect back
      }).catch((err) => {
        this.overlay = false;
        // conditional locking
        this.alert('500 server side error');
        // need to disable when minting
        console.log(err);
      });
    },
    clear() {
      this.data.file = null;
      this.data.previewFile = null;
      this.data.name = '';
      this.data.value = '';
      this.data.supply = '';
      this.data.collection = '';
      this.data.currency = '';
      this.data.blockChain = '';
      this.data.description = '';
      this.data.about = '';
      this.chips.length = 0;
    },
    loadCollections() {
      collectionAPI.getUserCollections(this.profile.userID).then((res) => {
        const myCollections = res.data.collections;
        // convert collection to data
        this.collectionOptions = [
          {
            text: 'New Collection',
            value: 'new_collection',
          },
        ];
        for (let i = 0; i < myCollections.length; i += 1) {
          this.collectionOptions.push({
            text: myCollections[i].collectionName,
            value: myCollections[i].publicID,
          });
        }
      });
    },
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1);
      this.chips = [...this.chips];
    },
  },
  mounted() {
    this.loadCollections();
  },
};
</script>
