<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h3 mb-10">
          Create New Collection
        </h1>

        <create-collection-form />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CreateCollectionForm from '@/features/forms/CreateCollectionForm.vue';

export default {
  name: 'CreateCollection',
  components: {
    'create-collection-form': CreateCollectionForm,
  },
};
</script>
