<template>
  <v-container fluid class="py-0 px-8">
    <v-row>
      <v-col cols="12">
        <section class="py-10">
          <page-header class="mb-8">Notification Settings</page-header>
          <profile-notification-settings-form />
        </section>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ProfileNotificationSettings',
  components: {
    'profile-notification-settings-form': () => import('@/features/forms/ProfileNotificationSettingsForm.vue'),
  },
  methods: {
    updateView(payload) {
      this.activeView = payload;
    },
  },
};
</script>
