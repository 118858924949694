import Vue from 'vue';
import vuetify from './plugins/Vuetify';
import './plugins/Vuex';
import './plugins/AsyncImports';
import './plugins/GlobalFilters';

import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
// eslint-disable-next-line no-unused-vars
Vue.config.errorHandler = (err, vm, info) => {
  console.log('err occured');
  if (err.response) {
    // eslint-disable-next-line no-alert
    window.alert(err.response.status);
  }
  console.log(err);
};

Vue.prototype.smallFakeContent = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.';
Vue.prototype.largeFakeContent = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. '
  + 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. '
  + 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ';

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
