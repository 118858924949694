<template>
  <section>
    <section class="banner-image pa-5 mb-10">
    <!-- Banner part -->
    <v-img
        height="500"
        width="100%"
        :aspect-ratio="16/9"
        :src="profile.banner"
        cover
        class="banner-image__background"
      />
        <v-btn v-if="isUser" outlined color="white" @click='handleFile'>
          <v-icon large>mdi-arrow-up-bold-box-outline</v-icon>
        </v-btn>
        <input
          v-if="isUser"
          ref = 'uploader'
          v-show = 'false'
          type='file'
          @change='onFileChanged'
        >
      <v-container class="banner-image__content">
        <v-row>
          <v-col cols="12"> <profile-quickview :isUser="isUser" :profile="profile"
          class="text-center text-md-left pa-4" />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-container>
      <v-row class="mb-4">
        <v-col cols="12" md="6" lg="4">
          <v-card class="pa-4" height="100%" rounded outlined>
            <h6 class="text-h6 text-left mb-3">Biography</h6>
            <v-divider class="mb-6" />
               <!-- user biography-->
            <p>{{profile.biography}}</p>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-card class="pa-4" height="100%" rounded outlined>
            <h6 class="text-h6 text-left mb-3">Social Media</h6>
            <v-divider class="mb-6" />
            <!-- TODO::embed links to various social media sites-->
            <v-btn
              v-for="icon in ['mdi-facebook', 'mdi-instagram', 'mdi-twitter']"
              :key="`user-profile-social-link-${icon}`"
              icon
            >
              <v-icon>
                {{ icon }}
              </v-icon>
            </v-btn>
          </v-card>
        </v-col>
        <v-col v-if="isUser" cols="12" md="6" lg="4">
          <v-card class="pa-4" height="100%" rounded outlined>
            <h6 class="text-h6 text-left mb-3">Additional Options</h6>
            <v-divider class="mb-6" />
            <profile-dashboard-actions />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" >
          <profile-dashboard-panels :id="this.getID"/>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProfileDashboardPanels from '@/features/profile-dashboard/ProfileDashboardPanels.vue';
import ProfileQuickview from '@/features/profile-dashboard/ProfileQuickview.vue';
import ProfileDashboardActions from '@/features/profile-dashboard/ProfileDashboardActions.vue';
import profileAPI from '@/api/profile';

export default {
  name: 'UserDashboard',
  props: {
    userID: String,
  },
  data() {
    return { // save state of profile dashboard
      overlay: false,
      absolute: true,
      profile: {
      },
    };
  },
  methods: {
    ...mapActions({ uploadFile: 'user/uploadFile', alert: 'alert/callErrorMessage' }), // uploading avatar
    handleFile() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      // call map action to read file from here
      console.log('file changed');
      const file = e.target.files[0];
      this.uploadFile({ file, imageType: 'banner' }).then(() => {
        this.profile.banner = this.user.profileBannerImageURL;
        console.log('test');
      }).catch((err) => {
        // alert message
        // TODO::// reset file back to original
        this.alert('Upload Error. Invalid file format');
        console.log(err);
      });
      console.log(file);
    },
    init() {
      let id = this.userID;
      if (this.$route.name === 'My Profile Dashboard') {
        id = this.user.userID;
      } else if (this.userID === this.user.userID) {
        this.$router.push({ name: 'My Profile Dashboard' });
      }
      profileAPI.getProfile(id).then((data) => { // catch for error messages
        this.profile = data;
      });
    },
  },
  components: {
    ProfileQuickview,
    ProfileDashboardPanels,
    ProfileDashboardActions,
  },
  computed: {
    ...mapGetters({
      user: 'user/profile',
    }),
    isUser() {
      return this.user.userID === this.userID || !this.userID;
    },
    getID() {
      let id = '';
      if (this.isUser) {
        id = this.user.userID;
      } else {
        id = this.userID;
      }
      return id;
    },
  },
  created() { // get info onload
    this.init();
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.init(); // initialized value
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/variables';

.banner-image {
  position: relative;
  display: flex;
  height: 500px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    justify-content: start;
  }

  &__background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__content {
    z-index: 1;
    background-color: rgba(0,0,0,0.5);
    margin-top: auto;
  }
}
</style>
