import axios from 'axios';

export default {
  getProfile: (userID) => {
    const url = `/api/user/getProfile/${userID}`;
    return axios.get(url).then((res) => {
      console.log(res.data);
      return res.data;
    });
  },
};
