<template>
  <section class="marketplace">
    <section class="marketplace-header d-sm-flex align-center mb-10">
      <p class="subtitle-2 text--secondary ma-0 d-none d-md-block results-count">{{ nfts.length }}
        out of {{count}} results</p>

      <section class="marketplace-header__sorting">
        <v-select
          :items="['Single Items', 'Bundles']"
          :menu-props="{ bottom: true, offsetY: true }"
          filled
          label="All Items"
          solo
          flat
          outlined
          hide-details
          class="items-select font-weight-bold"
        />

        <v-btn-toggle>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="fill-height"
                dense
                icon
                v-on="on"
                v-bind="attrs"
                @click="() => size = 'large'"
              >
                <v-icon :color="size === 'large' ? 'primary': ''">mdi-square</v-icon>
              </v-btn>
            </template>

            Large
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="fill-height"
                dense
                icon
                v-on="on"
                v-bind="attrs"
                @click.stop="size = 'small'"
              >
                <v-icon
                  :color="size === 'small' ? 'primary': ''"
                >mdi-view-grid</v-icon>
              </v-btn>
            </template>

            Small
          </v-tooltip>
        </v-btn-toggle>

        <v-select
          :items="[
            'Recently Created',
          ]"
          filled
          label="Sort by"
          solo
          :menu-props="{ bottom: true, offsetY: true }"
          hide-details
          flat
          outlined
          class="sort-select font-weight-bold black--text"
        />
      </section>
    </section>

    <nft-list :loading="loading" :nfts="nfts" :size="size" />
    <v-pagination
              v-model="page"
              class="my-4"
              @input="changedInput"
              :length="pageCount"
              :total-visible="7"
    ></v-pagination>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Marketplace',
  data: () => ({
    items: ['Foo', 'bar'],
    size: 'large',
    loading: true,
    page: 1,
  }),
  computed: {
    ...mapGetters({ // modify nft models here
      nfts: 'nfts/all',
      count: 'nfts/count',
      pageCount: 'nfts/pageCount',
    }),
  },
  methods: {
    ...mapActions({ getNFTs: 'nfts/getNFTS' }),
    changedInput() {
      // const { s } = this.$route.query;
      this.setLoad();
      // this.getNFTs({ page: this.page, search: s });
      const currentParams = this.$router.currentRoute.query;
      const mergedParams = { ...currentParams, p: this.page };
      this.$router.replace({ query: mergedParams });
    },
    setLoad() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1500);
    },
  },
  mounted() {
    // set nft to current page number
    const that = this;
    const {
      p, s, category, blockchain, currency, minVal, maxVal, onSale,
    } = this.$route.query;
    console.log('p is', p);
    if (!p) {
      this.getNFTs({
        page: 1, search: s, category, blockchain, currency, minVal, maxVal, onSale,
      });
    } else {
      this.getNFTs({
        page: p, search: s, category, blockchain, currency, minVal, maxVal, onSale,
      });
      this.page = parseInt(p, 10);
    }
    setTimeout(() => {
      that.loading = false;
    }, 1200);
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      const {
        p, s, category, blockchain, currency, minVal, maxVal, onSale,
      } = this.$route.query;
      this.setLoad();
      let page = p;

      if (!p) {
        page = 1;
        this.page = 1;
      } else {
        this.page = parseInt(p, 10);
      }
      this.getNFTs({
        page, search: s, category, blockchain, currency, minVal, maxVal, onSale,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/variables';

.marketplace {
  padding: 50px 15px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 75px 50px 50px;
  }
}

.marketplace-header__sorting {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 15px;
  align-content: stretch;
  width: 100%;
  max-width: 100%;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: 1fr 1fr auto;
    justify-content: flex-end;
    align-content: flex-end;
    width: 500px;
    margin-left: auto;
    max-width: 100%;

    .sort-select {
      grid-row: 1;
      grid-column-start: 2;
    }
  }

  .v-btn-toggle .v-btn { height: 100% !important; }
}

.results-count {
  padding-right: 40px;
  min-width: 150px;
}

.nft-skeleton-loader {
  .v-skeleton-loader__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
