var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"marketplace"},[_c('section',{staticClass:"marketplace-header d-sm-flex align-center mb-10"},[_c('p',{staticClass:"subtitle-2 text--secondary ma-0 d-none d-md-block results-count"},[_vm._v(_vm._s(_vm.nfts.length)+" out of "+_vm._s(_vm.count)+" results")]),_c('section',{staticClass:"marketplace-header__sorting"},[_c('v-select',{staticClass:"items-select font-weight-bold",attrs:{"items":['Single Items', 'Bundles'],"menu-props":{ bottom: true, offsetY: true },"filled":"","label":"All Items","solo":"","flat":"","outlined":"","hide-details":""}}),_c('v-btn-toggle',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"fill-height",attrs:{"dense":"","icon":""},on:{"click":function () { return _vm.size = 'large'; }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.size === 'large' ? 'primary': ''}},[_vm._v("mdi-square")])],1)]}}])},[_vm._v(" Large ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"fill-height",attrs:{"dense":"","icon":""},on:{"click":function($event){$event.stopPropagation();_vm.size = 'small'}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.size === 'small' ? 'primary': ''}},[_vm._v("mdi-view-grid")])],1)]}}])},[_vm._v(" Small ")])],1),_c('v-select',{staticClass:"sort-select font-weight-bold black--text",attrs:{"items":[
          'Recently Created' ],"filled":"","label":"Sort by","solo":"","menu-props":{ bottom: true, offsetY: true },"hide-details":"","flat":"","outlined":""}})],1)]),_c('nft-list',{attrs:{"loading":_vm.loading,"nfts":_vm.nfts,"size":_vm.size}}),_c('v-pagination',{staticClass:"my-4",attrs:{"length":_vm.pageCount,"total-visible":7},on:{"input":_vm.changedInput},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }