<template>
  <section class="py-8">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <page-header class="text-center mb-4">Explore Collections</page-header>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col
          class="pb-0"
          cols="12"
        >
          <v-tabs
            v-model="activeTab"
            centered
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.key"
              :href="`#tab-${tab.key}`"
            >
              {{ tab.text }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="mb-5" />
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-tabs-items v-model="activeTab">
            <v-tab-item
              v-for="tab in tabs"
              :key="tab.key"
              :value="`tab-${tab.key}`"
            >
              <collections-list
                :collections="collections"
                :loading="loading"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'explore',
  data: () => ({
    activeTab: null,
    tabs: [
      {
        text: 'Trending',
        key: 'tab-trending',
      },
      {
        text: 'Top',
        key: 'tab-top',
      },
      {
        text: 'Art',
        key: 'tab-art',
      },
      {
        text: 'Music',
        key: 'tab-music',
      },
      {
        text: 'Domain Names',
        key: 'tab-domain-names',
      },
      {
        text: 'Virtual Worlds',
        key: 'tab-virtual-worlds',
      },
      {
        text: 'Trading Cards',
        key: 'tab-trending-cards',
      },
      {
        text: 'Collectibles',
        key: 'tab-collectibles',
      },
      {
        text: 'Sports',
        key: 'tab-sports',
      },
      {
        text: 'Utility',
        key: 'tab-utility',
      },
    ],
    loading: true,
  }),
  computed: {
    ...mapGetters({
      collections: 'collections/all',
    }),
  },
  mounted() {
    const that = this;

    setTimeout(() => {
      that.loading = false;
    }, 2500);
  },
  methods: {
    resetLoading() {
      const that = this;

      setTimeout(() => {
        that.loading = false;
      }, 2500);
    },
  },
  watch: {
    activeTab: {
      handler() {
        this.loading = true;
      },
    },
    loading: {
      handler(val) {
        if (val) this.resetLoading();
      },
    },
  },
};
</script>
