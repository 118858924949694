<template>
  <section>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      centered
      color="secondary"
      grow
      icons-and-text
      show-arrows
    >
      <v-tabs-slider />

      <v-tab
        class="secondary--text d-flex flex-row align-center"
        href="#tab-collected"
      >
        <v-icon class="my-0">mdi-grid</v-icon>
        <p class="mx-3 my-0 font-weight-bold text-capitalize">
          Collected
        </p>
        <span class="subtitle-2 mt-n1 mx-0 text--secondary">{{myNfts.length}}</span>
      </v-tab>

      <v-tab
        class="secondary--text d-flex flex-row align-center"
        href="#tab-created"
      >
        <v-icon>mdi-format-paint</v-icon>
        <p class="mx-3 my-0 font-weight-bold text-capitalize">
          Created
        </p>
        <span class="subtitle-2 mt-n1 mx-0 text--secondary">{{createdNFTs.length}}</span>
      </v-tab>

      <v-tab
        class="secondary--text d-flex flex-row align-center"
        href="#tab-favourites"
      >
        <v-icon>mdi-heart-outline</v-icon>
        <p class="mx-3 my-0 font-weight-bold text-capitalize">
          Favourites
        </p>
        <span class="subtitle-2 mt-n1 mx-0 text--secondary">{{likedNFTs.length}}</span>
      </v-tab>

      <v-tab
        class="secondary--text d-flex flex-row align-center"
        href="#tab-collections"
      >
        <v-icon>mdi-heart-outline</v-icon>
        <p class="mx-3 my-0 font-weight-bold text-capitalize">
          Collections
        </p>
        <span class="subtitle-2 mt-n1 mx-0 text--secondary">{{myCollections.length}}</span>
      </v-tab>
      <v-spacer />
    </v-tabs>

    <v-divider class="mb-4" />

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-collected">
        <v-row>
          <v-col
            v-for="(nft, x) in myNfts.slice(0, myNfts.length)"
            :key="`profile-owned-nft-${x}`"
            cols="12"
            lg="4"
            md="6"
            xl="3"
          >
            <nft-card v-bind="convert(nft)" :image-height="300" />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="tab-created">
      <v-row>
          <v-col
            v-for="(nft, x) in createdNFTs.slice(0, createdNFTs.length)"
            :key="`profile-created-nft-${x}`"
            cols="12"
            lg="4"
            md="6"
            xl="3"
          >
            <nft-card v-bind="convert(nft)" :image-height="300" />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="tab-favourites">
        <v-row>
          <v-col
            v-for="(nft, x) in likedNFTs.slice(0, likedNFTs.length)"
            :key="`profile-owned-nft-${x}`"
            cols="12"
            lg="4"
            md="6"
            xl="3"
          >
            <nft-card v-bind="convert(nft)" :image-height="300" />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="tab-collections">
        <v-row>
          <v-col
            v-for="(collection, x) in myCollections.slice(0, myCollections.length)"
            :key="`mycollections-${x}`"
            cols="12"
            lg="4"
            md="6"
            xl="3"
          >
            <collection-item
              :key="`collections-list-item-${collection.publicID}`"
              :imageHeight="size === 'small' ? 150 : 200"
              v-bind="collection"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>

<script>
import BigNumber from 'bignumber.js';
import itemAPI from '@/api/item';
import collectionAPI from '@/api/collection';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    'collection-item': () => import('@/features/collection-card/CollectionItem.vue'),
  },
  data() {
    return {
      tab: null,
      text: 'testing changed info',
      myNfts: [], // my nfts
      createdNFTs: [], // item created
      likedNFTs: [],
      myCollections: [],
    };
  },
  mounted() {
    this.checkForTabQuery();
  },
  computed: {

  },
  methods: {
    checkForTabQuery() {
      this.getMyItems();
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'tab') && this.$route.query.tab) {
        const tabmap = {
          favourites: 'tab-favourites',
          collected: 'tab-collected',
          created: 'tab-created',
        };

        this.tab = tabmap[this.$route.query.tab] || null;
      }
    },
    getMyItems() {
      itemAPI.getItemList(this.id).then((res) => {
        this.myNfts = res.data;
      });
      itemAPI.getCreatedList(this.id).then((res) => {
        this.createdNFTs = res.data;
      });
      itemAPI.getFavList(this.id).then((res) => {
        this.likedNFTs = res.data;
      });
      collectionAPI.getUserCollections(this.id).then((res) => {
        this.myCollections = res.data.collections;
      });
      // get collection
    },
    convert(nft) {
      let calculatedVal = BigNumber(nft.value);
      calculatedVal = calculatedVal.div(1000000000); // convert to eth
      return {
        publicID: nft.publicID,
        contentType: nft.contentType,
        contentURL: nft.fileURL,
        mimeType: nft.mimeType,
        itemName: nft.itemName,
        likeCount: nft.likes,
        liked: nft.liked,
        onSale: nft.onSale,
        previewURL: nft.thumbnailURL, // will need to fix this part in order to be more consistent
        symbol: nft.currency,
        contractAddress: nft.contractAddress,
        value: calculatedVal.toString(),
      };
    },
    convertCollection(collection) { // display collection card
      console.log(collection);
    },
    getCreated() {

    },
    getMyCollection() {

    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.getMyItems();
    },
    '$route.query': {
      handler: 'checkForTabQuery',
      deep: true,
    },
  },
};
</script>s

<style
  lang="scss"
  scoped
>
.offers-dropdown {
  width: 100px;
  min-width: 0;

  &::before {
    display: none !important;
  }
}

;
</style>
