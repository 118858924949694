<template>
    <form>
      <!-- Name -->
      <input-label>
        Collection Name <span class="red--text">*</span>
      </input-label>
        <v-text-field
          v-model="data.name"
          label="Collection Name"
          :error-messages = "errorMessages.itemNameMsg"
          hint="No spaces or special characters allowed. Use _ or - instead."
          :rules = "[rules.itemNameCount, rules.itemNameSpelling]"
          counter = "30"
          flat
          solo
          outlined
        ></v-text-field>

      <input-label>Blockchain <span class="red--text">*</span></input-label>
      <!-- Blockchain -->
        <v-select
          v-model="data.blockChain"
          :items="blockChainOptions"
          data-vv-name="select"
          :rules = "[rules.notEmpty]"
          label="Blockchain"
          flat
          solo
          outlined
        />

      <!-- / Blockchain -->
      <input-label>
        Description <span class="red--text">*</span>
      </input-label>
      <p class="body-2 text--secondary">
        The description will be included on the item's detail
      page underneath its image. Markdown syntax is supported.
      </p>
        <v-textarea
          v-model="data.description"
          name="input-7-1"
          label="Description"
          :rules = "[rules.notEmpty]"
          hint=""
          flat
          solo
          outlined
        ></v-textarea>

      <v-btn
        class="mr-4"
        :disabled="!passValidation"
        @click="submit"
      >
        submit
      </v-btn>
      <v-btn @click="clear">
        clear
      </v-btn>
          <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    </form>
</template>

<script>
import { mapActions } from 'vuex';
import axios from 'axios';

export default {
  name: 'CreateNFTForm',
  data() {
    return {
      snackbar: false,
      overlay: false,
      data: {
        name: '',
        blockChain: '',
        description: '',
      },
      blockChainOptions: [
        {
          text: 'binance',
          value: 'binance',
        },
      ],
      rules: { // input rules
        itemNameCount: (value) => value.length <= 30 || 'Max 30 characters',
        itemNameSpelling: (value) => {
          const regex = /^[\w\d-_]+$/g;
          return regex.test(value) || 'Invalid username';
        },
        notEmpty: (value) => value !== '' || 'Field cannot be empty',
      },
      errorMessages: { // for adding error messages to each field
        itemNameMsg: '',
      },
    };
  },
  computed: {
    passValidation() {
      let pass = (this.data.name !== ''
      || this.data.blockChain !== ''
      || this.data.description !== '');
      pass = pass && typeof this.rules.itemNameCount(this.data.name) === 'boolean';
      pass = pass && typeof this.rules.itemNameSpelling(this.data.name) === 'boolean';
      pass = pass && typeof this.rules.notEmpty(this.data.blockChain) === 'boolean';
      pass = pass && typeof this.rules.notEmpty(this.data.description) === 'boolean';
      return pass;
    },
  },
  methods: {
    ...mapActions({ getNFTs: 'nfts/getNFTS' }),
    // submit and redirects user
    submit() {
      console.log('creating nft');
      const url = '/api/collection/addCollection';
      const authToken = localStorage.getItem('token');
      // header
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const collectionData = {
        description: this.data.description,
        blockchain: this.data.blockChain,
        collectionName: this.data.name,
      };
      this.overlay = true;
      // sending data to backend
      axios.post(url, collectionData, { headers }).then((res) => {
        console.log(res);
        this.overlay = false;
        // change redirection path
        this.$router.push({ name: 'Assets' });
        // redirect back
      }).catch((err) => {
        this.overlay = false;
        // need to disable when minting
        console.log(err);
      });
    },
    clear() {
      this.data.name = '';
      this.data.description = '';
      this.data.blockChain = '';
    },
  },
};
</script>
