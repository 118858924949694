<template>
  <v-container>
    <v-row
      class="mb-16 py-16"
      justify="center"
    >
      <v-col
        cols="12"
        md="12"
        sm="8"
      >
        <hero-section>
          <template v-slot:content>
            <v-slide-x-transition appear>
              <div>
                <h1 class="text-h3 mb-4 font-weight-bold">{{ heroSection.title }}</h1>
                <p class="text-h5 text--grey mb-10">
                  {{ heroSection.content }}
                </p>

                <section class="d-sm-flex mb-16">
                  <v-btn
                    v-for="action in heroSection.actions"
                    :key="action.title"
                    v-bind="action.attributes"
                  >
                    {{ action.title }}
                  </v-btn>
                </section>

                <v-hover v-slot="{ hover }">
                  <router-link
                    :class="hover ? 'text--secondary' : ''"
                    class="primary--text cursor--pointer"
                    tag="span"
                    to="/"
                  >
                    <v-icon
                      :color="hover ? 'secondary' : 'primary'"
                    >
                      mdi-motion-play-outline
                    </v-icon>
                    Learn more about RIIVR
                  </router-link>
                </v-hover>
              </div>
            </v-slide-x-transition>
          </template>
          <template v-slot:feature>
          <nft-card
            :imageHeight="340"
            v-bind="convert(nft)"
          />
          </template>
        </hero-section>
      </v-col>
    </v-row>
    <v-row class="mb-16">
      <v-col cols="12">
        <h2 class="text-h4 mb-10 text-center">
          Create and sell your NFTs
        </h2>
      </v-col>
      <v-col
        v-for="item in items"
        :key="item.title"
        cols="12"
        md="3"
        sm="6"
      >
        <v-card
          class="text-center"
          elevation="0"
          justifyContent="center"
        >
          <section>
            <v-icon
              class="mx-auto mb-3"
              color="primary"
            >
              {{ item.icon }}
            </v-icon>
          </section>
          <v-card-subtitle class="text-center w-100 pa-0 text-h6 text--black mb-4">
            {{ item.title }}
          </v-card-subtitle>
          <v-card-text>
            <p class="text-caption">
              {{ item.message }}
            </p>
          </v-card-text>

          <v-btn
            class="text-capitalize"
            color="primary"
            :href="item.link"
          >
            {{ item.buttonTitle }}
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BigNumber from 'bignumber.js';
import itemAPI from '@/api/item';

export default {
  name: 'Home',
  components: {
  },
  data: () => ({
    heroSection: {
      title: 'Vita Brevis, Ars Longa, But Riivr longer',
      content: 'We\'re the NFT marketplace by artists for artists',
      actions: [
        {
          title: 'Explore',
          attributes: {
            'x-large': true,
            color: 'primary',
            to: '/assets?category=new',
            class: 'mr-3',
          },
        },
        {
          title: 'Create',
          attributes: {
            'x-large': true,
            outlined: true,
            color: 'primary',
            to: '/create',
          },
        },
      ],
    },
    items: [
      {
        icon: 'mdi-wallet',
        title: 'Setup your wallet',
        message: 'Set up your metamask wallet for trade.',
        link: 'https://metamask.io/',
        buttonTitle: 'Setup Now',
      },
      {
        icon: 'mdi-shape',
        title: 'Create your collection',
        message: 'Create a collection of your NFTs.',
        link: '/create/collection',
        buttonTitle: 'Create',
      },
      {
        icon: 'mdi-image',
        title: 'Add your NFTs',
        message: 'Create an NFT and share it to others.',
        link: '/create',
        buttonTitle: 'Add NFTs',
      },
      {
        icon: 'mdi-tag',
        title: 'Items For Sale',
        message: 'Find a work of art and claim it as your own',
        link: '/assets',
        buttonTitle: 'View',
      },
    ],
    nft: {
      mimeType: 'image/png',
      contentType: 'Art',
      fileURL: '',
      itemName: '',
    },
  }),
  methods: {
    init() {
      itemAPI.getRandomItem().then((res) => {
        this.nft = res.data;
      });
    },
    convert(nft) {
      let calculatedVal = BigNumber(nft.value);
      calculatedVal = calculatedVal.div(1000000000); // convert to eth
      return {
        publicID: nft.publicID,
        contentType: nft.contentType,
        contentURL: nft.fileURL,
        mimeType: nft.mimeType,
        itemName: nft.itemName,
        likeCount: nft.likes,
        liked: nft.liked,
        onSale: nft.onSale,
        previewURL: nft.thumbnailURL, // will need to fix this part in order to be more consistent
        symbol: nft.currency,
        contractAddress: nft.contractAddress,
        value: calculatedVal.toString(),
        likeable: false,
      };
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    featuredCollection() {
      return this.collections.slice(0, 1).shift();
    },
  },
};
</script>
