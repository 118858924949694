<template>
  <v-app>
    <app-bar />

    <v-navigation-drawer
      v-if="isProfileSettingsRoute"
      class="pa-4 d-none d-md-block"
      absolute
      app
      permanent
      clipped
    >
      <h6 class="text-h6 text-capitalize">Settings</h6>

      <v-list>
        <v-list-item
          v-for="navItem in navItems"
          :key="`profile-settings-nav-item=${navItem.title}`"
          link
          :to="navItem.to"
          exact-path
        >
          <v-list-item-icon>
            <v-icon left>
              {{ navItem.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ navItem.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <marketplace-filters-sidebar v-if="showMarketplaceFilters" />

    <v-main :class="{ 'px-0': isCollectionRoute }">
      <mobile-navigation-drawer />
      <v-slide-x-transition mode="out-in">
        <router-view />
      </v-slide-x-transition>
    </v-main>
    <snackbar-alert />
    <app-footer />
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {
    'app-bar': () => import('@/features/app-structure/AppBar.vue'),
    'app-footer': () => import('@/features/app-structure/AppFooter.vue'),
    'mobile-navigation-drawer': () => import('@/features/mobile-navigation-drawer/MobileNavigationDrawer.vue'),
    'marketplace-filters-sidebar': () => import('@/features/marketplace-filters-sidebar/MarketplaceFiltersSidebar.vue'),
    'snackbar-alert': () => import('@/features/alerts/snackbarAlert.vue'),
  },
  data: () => ({
    navItems: [
      {
        icon: 'mdi-account-box',
        title: 'Profile',
        to: '/profile/settings',
      },
      {
        icon: 'mdi-bell',
        title: 'Notifications',
        to: '/profile/settings/notifications',
      },
      {
        icon: 'mdi-tag',
        title: 'Offers',
        to: '/profile/settings/offers',
      },
    ],
  }),
  computed: {
    isProfileSettingsRoute() {
      return this.routeIncludes('/profile/settings');
    },
    isMarketplaceRoute() {
      return this.isRoute('/assets');
    },
    isCollectionRoute() {
      return this.routeIncludes('/assets/collections/');
    },
    showMarketplaceFilters() {
      return this.isMarketplaceRoute;
    },
  },
  created() {
    console.log('front page on');
    window.ethereum.on('accountsChanged', (accounts) => {
      console.log(accounts);
      this.$router.push({ path: '/logout' });
    });
  },
  methods: {
    isRoute(route) {
      return this.$route.path === route;
    },
    routeIncludes(route) {
      return this.$route.path.includes(route);
    },
  },
};
</script>

<style lang="scss">
.cursor--pointer {
  cursor: pointer;
}

.disabled-active-link::before {
 display: none;
}

.disable-prepend-inner-margin.v-text-field .v-input__prepend-inner {
  margin-top: 0;
}

.fill-width {
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  //box-shadow: inset 0 0 3px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(53, 56, 54);
  border-radius: 10px;
}

.expansion-panel--disable-content-padding .v-expansion-panel-content__wrap {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.v-expansion-panel-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mw-100 {
  max-width: 100% !important;
}

.vertical-icon-button .v-btn__content {
  display: block;
}
</style>
