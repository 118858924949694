import Vue from 'vue';

/**
 * Vue Filter toCurrency
 *
 * @usage {{ item.price | toCurrency }}
 */
Vue.filter('toCurrency', (val = null) => {
  try {
    if (val === null) return '-';

    return new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'AUD',
      currencyDisplay: 'narrowSymbol',
      currencySign: 'accounting',
    }).format(val);
  } catch {
    return val;
  }
});

/**
 * Format Ethereum Price
 *
 * @usage {{ item.price | formatEthereumPrice }}
 */
Vue.filter('formatEthereumPrice', (val = null) => {
  try {
    if (val === null) return '-';

    // eslint-disable
    return Number(val).toFixed(3);
  } catch {
    return val;
  }
});
