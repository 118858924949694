<template>
  <div>
    <v-container class="mt-8">
      <v-row>
        <v-col
          cols="12"
          md="7"
        >
          <h3 class="text-h3 mb-8">Commission NFT</h3>
          <commission-nft-form :collectionID="collectionID" />
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <collection-card
            class="mt-12 mt-md-0"
            v-bind="featuredCollection"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CommissionNFTForm from '@/features/forms/CommissionNFTForm.vue';

export default {
  name: 'CommissionNFT',
  props: {
    collectionID: {
      type: [String, Number],
      required: false,
      default: () => (''),
    },
  },
  components: {
    'commission-nft-form': CommissionNFTForm,
  },
  data: () => ({
    valid: true,
    name: '',
    nameRules: [
      (v) => !!v || 'Name is required',
      (v) => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    select: null,
    items: [
      'Item 1',
      'Item 2',
      'Item 3',
      'Item 4',
    ],
    checkbox: false,
  }),
  computed: {
    ...mapGetters({
      collections: 'collections/all',
    }),
    featuredCollection() {
      return this.collections.slice(0, 1)
        .shift();
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style lang="scss">
.type-buttons .v-btn:not(:last-of-type) {
  border-right: 2px solid #000;
  //rgba(0, 0, 0, 0.12);
}
</style>
