export default {
  SET_NFTS(state, payload) {
    // state.nfts = payload.nfts.items;
    state.nfts = [];
    for (let i = 0; i < payload.nfts.items.length; i += 1) {
      const item = payload.nfts.items[i];
      item.liked = payload.likes.includes(item.publicID);
      state.nfts.push(item);
    }
    state.pageCount = Math.floor(payload.nfts.count / 20) + 1;
    state.count = payload.nfts.count;
  },
  LIKE_ITEM(state, payload) {
    console.log(payload);
    console.log(state.nfts);
    const foundIndex = state.nfts.findIndex((x) => x.publicID === payload.publicID);
    console.log(foundIndex);
    if (foundIndex !== -1) {
      console.log('found');
      state.nfts[foundIndex].liked = true;
      state.nfts[foundIndex].likes += 1;
    }
  },
  UNLIKE_ITEM(state, payload) {
    console.log(payload);
    const foundIndex = state.nfts.findIndex((x) => x.publicID === payload.publicID);
    console.log(foundIndex);
    if (foundIndex !== -1 && state.nfts[foundIndex].likes !== 0) {
      console.log('found');
      state.nfts[foundIndex].liked = false;
      state.nfts[foundIndex].likes -= 1;
    }
  },
};
