<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-text-field
      v-model="name"
      :rules="nameRules"
      label="Name"
      required
    />

    <v-file-input
      id="file"
      v-model="file"
      label="Files"
      truncate-length="15"
      accept=".jpg, .jpeg, .png, .gif, .svg, .mp4, .webm, .mp3, .wav, .ogg, .glb, .gltf"
      :rules="[
        value => !value || value.size < 100000000 || 'File Size should be less than 100mb.'
      ]"
      solo
      flat
      outlined
      hide-details="auto"
      multiple
    />

    <v-text-field
      v-model="email"
      :rules="emailRules"
      label="E-mail"
      required
    ></v-text-field>

    <v-textarea
      v-model="notes"
      name="input-7-1"
      label="Notes"
      hint=""
      hide-details="auto"
      outlined
    />

    <p class="red--text">
      You can revise 2 times maximum.
    </p>

    <v-btn
      :disabled="!valid"
      class="mr-4"
      color="success"
      @click="validate"
    >
      Validate
    </v-btn>

    <v-btn
      class="mr-4"
      color="error"
      @click="reset"
    >
      Reset Form
    </v-btn>

    <v-btn
      color="warning"
      @click="resetValidation"
    >
      Reset Validation
    </v-btn>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    name: '',
    file: null,
    notes: '',
    nameRules: [
      (v) => !!v || 'Name is required',
      (v) => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    select: null,
    checkbox: false,
  }),

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
