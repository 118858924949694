<template>
  <v-container class="mt-15">
    <v-row>
      <v-col cols="12">
        <!--add a pop up bar with event emit from the nft
        action bar-->
        <nft-listing-actions-bar v-show="isOwner" :isOwner="isOwner" :nft="nft" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        lg="5"
        md="6"
        order="2"
        order-md="1"
      >
        <nft-listing-image class="d-none d-md-block"
        :imageURL="coverUrl" :fileURL="nft.fileURL" :mimeType="nft.mimeType"
        :contentType="nft.contentType"/>
        <h1 class="text-h3 mb-4 font-weight-bold">{{ nft.itemName }}</h1>
        <nft-listing-description class="mb-4"
        :about="nft.description" :creator="nft.creator.username" :tags="nft.tags"/>
        <nft-listing-about :about="nft.about" class="mb-4" />
        <nft-listing-details class="mb-4" :nft="nft"/>
      </v-col>
      <v-col
        cols="12"
        lg="7"
        md="6"
        order="1"
        order-md="2"
      >
        <v-sheet width="450" class="mw-100 mx-auto">
          <nft-listing-image v-show="true" class="d-block d-md-none"
          :imageURL="coverUrl" :fileURL="nft.fileURL" :mimeType="nft.mimeType"
          :contentType="nft.contentType"/>
        </v-sheet>

        <!-- eslint-disable max-len -->
        <section
          class="d-flex flex-wrap flex-column justify-center flex-sm-row justify-sm-space-between align-center text-center mb-4">
          <router-link
            class="mb-3 mb-sm-0"
            :to="`/assets/collections/${nft.itemCollection.publicID}`"
          >
            {{nft.itemCollection.collectionName}}
          </router-link>

          <nft-listing-social-actions-bar />
        </section>

        <nft-listing-title
          class="mb-5"
          v-show="nft.supply>1"
          :title="nft.username"
        />

        <p class="text--secondary text-center text-md-left">
          Owned by
          <router-link :to='profileLink'>{{nft.owner.username}}</router-link>
          <v-icon class="ml-3 mr-1">mdi-eye</v-icon>
          <span>{{nft.views}} view</span>
        </p>
        <!--overlay system-->
        <nft-listing-cart-block class="mb-8" :id="id" :value="nft.value" :isOwner="isOwner"
        :nft="nft" @openOverlay="openOverlay" @closeOverlay="closeOverlay"/>
        <nft-listing-description-panels  v-show="false"/>
      </v-col>
    </v-row>
    <!--overlay info-->
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import nftAPI from '@/api/item';

export default {
  name: 'NFTPage',
  components: {
    'nft-listing-image': () => import('@/features/nfts/nft-listing/NFTListingImage.vue'),
    'nft-listing-title': () => import('@/features/nfts/nft-listing/NFTListingTitle.vue'),
    'nft-listing-actions-bar': () => import('@/features/nfts/nft-listing/NFTListingActionsBar.vue'),
    'nft-listing-details': () => import('@/features/nfts/nft-listing/NFTListingDetails.vue'),
    'nft-listing-social-actions-bar': () => import('@/features/nfts/nft-listing/NFTListingSocialActionsBar.vue'),
    'nft-listing-about': () => import('@/features/nfts/nft-listing/NFTListingAbout.vue'),
    'nft-listing-description': () => import('@/features/nfts/nft-listing/NFTListingDescription.vue'),
    'nft-listing-description-panels': () => import('@/features/nfts/nft-listing/NFTListingDescriptionPanels.vue'),
    'nft-listing-cart-block': () => import('@/features/nfts/nft-listing/NFTListingCartBlock.vue'),
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fill: true,
      overlay: false,
      nft: {
        creator: {
          username: '',
          publicID: '',
        },
        owner: {
          username: '',
          publicID: '',
          publicAddress: '',
        },
        itemCollection: {
          publicID: '',
          collectionName: '',
        },
        contractAddress: '',
        tags: [],
        itemName: '',
        ipfsURL: '',
        ipfsHash: '',
        mimeType: '',
        value: '0',
        about: '',
        description: '',
        fileURL: '',
        thumbnailURL: '',
        currency: '',
        blockChain: '',
        status: {
          minted: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      profile: 'user/profile',
    }),
    isOwner() {
      console.log(this.nft.owner.publicID);
      console.log(this.profile.userID);
      return this.nft.owner.publicID === this.profile.userID;
    },
    onSale() {
      console.log(this.nft);
      return this.nft.onSale;
    },
    profileLink() {
      return `/profile/${this.nft.owner.publicID}`;
    },
    coverUrl() {
      if (this.nft.contentType === 'Art') {
        return this.nft.fileURL;
      } if (this.nft.contentType === 'Music') {
        return this.nft.coverURL;
      }
      return '';
    },
  },
  methods: {
    ...mapActions({ getItem: 'nftItem/getItem' }),
    init() {
      // use api
      nftAPI.getItem(this.id).then((data) => {
        this.nft = data;
      });
      // profileAPI.getProfile(this.profile.userID).then((data) => { // catch for error messages
      //   this.profile = data;
      // });
    },
    openOverlay() {
      // overlay on
      this.overlay = true;
    },
    closeOverlay() {
      // overlay off
      this.overlay = false;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.init(); // initialized value
    },
  },
  mounted() {
    this.init();
  },
};
</script>
