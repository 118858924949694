export default {
  isAuthenticated: false,
  authToken: '',
  profile: { // stuff
    userID: '',
    username: '',
    thumbnailImageURL: 'https://riivr.sgp1.digitaloceanspaces.com/default/user.png',
    profileBannerImageURL: 'https://riivr.sgp1.digitaloceanspaces.com/default/user.png',
    walletAddress: '',
    likes: [],
    bio: '',
    email: '',
    links: {
      twitter: '',
      website: '',
      instagram: '',
      facebook: '',
    },
    refCode: '',
    settings: { // settings to be changed later on
      notifications: {
        'item-sold': false,
        'bid-activity': false,
        'price-change': false,
        'auction-expiration': false,
        outbid: false,
        'successful-purchase': false,
        'minimum-bid': 0.005,
      },
    },
  },
};
