import Vue from 'vue';
import VueRouter from 'vue-router';

// Views
import Home from '../views/Home.vue';
import ProfileDashboard from '../views/ProfileDashboard.vue';
import ProfileSettings from '../views/ProfileSettings.vue';
import CreateNFT from '../views/CreateNFT.vue';
import CreateCollection from '../views/CreateCollection.vue';
import Marketplace from '../views/Marketplace.vue';
import ProfileNotificationSettings from '../views/ProfileNotificationSettings.vue';
import ProfileOfferSettings from '../views/ProfileOfferSettings.vue';
import NFTPage from '../views/NFTPage.vue';
import Explore from '../views/Explore.vue';
import AtlantisStore from '../views/AtlantisStore.vue';
import Statistics from '../views/Statistics.vue';
import CollectionPage from '../views/CollectionPage.vue';
import CollectionSalePage from '../views/CollectionSalePage.vue';
import CommissionNFTPage from '../views/CommissionNFT.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/explore',
    name: 'Explore',
    component: Explore,
  },
  {
    path: '/assets',
    name: 'Assets',
    component: Marketplace,
  },
  {
    path: '/assets/nfts/:id',
    name: 'NFT Asset Page',
    component: NFTPage,
    props: true,
  },
  {
    path: '/assets/collections/:collectionID',
    name: 'Collection Page',
    component: CollectionPage,
    props: true,
  },
  {
    path: '/assets/collections/:collectionID/list-for-sale',
    name: 'Collection Sale Page',
    component: CollectionSalePage,
    props: true,
  },
  {
    path: '/new-atlantis-store',
    name: 'New Atlantis Store',
    component: AtlantisStore,
  },
  {
    path: '/stats',
    name: 'Statistics',
    component: Statistics,
  },
  {
    path: '/create',
    name: 'Create NFT',
    component: CreateNFT,
    meta: { requiresLogin: true },
  },
  {
    path: '/create/collection',
    name: 'Create Collection',
    component: CreateCollection,
    meta: { requiresLogin: true },
  },
  {
    path: '/myProfile',
    name: 'My Profile Dashboard',
    component: ProfileDashboard,
    meta: { requiresLogin: true },
  },
  {
    path: '/profile/:userID',
    name: 'Profile Dashboard',
    component: ProfileDashboard,
    props: true, // added props for user
  },
  {
    path: '/myProfile/settings',
    name: 'My Profile Settings',
    component: ProfileSettings,
    meta: { requiresLogin: true },
  },
  {
    path: '/myProfile/settings/notifications',
    name: 'My Profile Notification Settings',
    component: ProfileNotificationSettings,
    meta: { requiresLogin: true },
  },
  {
    path: '/myProfile/settings/offers',
    name: 'My Profile Offers Settings',
    component: ProfileOfferSettings,
    meta: { requiresLogin: true },
  },
  {
    path: '/commission-nft',
    name: 'Commission NFT',
    component: CommissionNFTPage,
    meta: { requiresLogin: true },
  },
  {
    path: '/logout',
    name: 'Logout',
  },
];

// access global store from
// router.app.$store

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return (savedPosition || { x: 0, y: 0, behaviour: 'smooth' });
  },
});

router.beforeEach((to, from, next) => {
  if (to.name === 'Logout') {
    router.app.$store.commit('user/LOG_OFF');
    console.log('logout');
    next('/');
  } else if (!router.app.$store.getters['user/userID'] && localStorage.getItem('token')) { // get initial data
    router.app.$store.dispatch('user/getUser').then(() => {
      next();
    }).catch((err) => {
      console.log(err);
      router.app.$store.commit('user/LOG_OFF');
      next();
    });
  } else if (to.matched.some((record) => record.meta.requiresLogin) && !localStorage.getItem('token')) {
    router.app.$store.dispatch('user/login');
    // will have to implement sign in from here
    next('/');
  } else {
    next();
  }
});

export default router;
