export default {
  namespaced: true,
  state: {
    showAlert: false,
    showLoading: false,
    message: '',
    timeout: -1,
  },
  getters: {
    alert: (state) => state,
  },
  mutations: {
    SET_SHOW(state, { msg, timeID }) {
      state.showAlert = true;
      state.timeout = timeID;
      state.message = msg;
    },
    CLEAR_SHOW(state) {
      state.showAlert = false;
      state.message = '';
      if (state.timeout !== -1) {
        clearTimeout(state.timeout);
        state.timeout = -1;
      }
    },
  },
  actions: { // one error at a time
    callErrorMessage({ commit }, message) {
      console.log('message');
      console.log(message);
      commit('CLEAR_SHOW');
      const timeout = setTimeout(() => {
        commit('CLEAR_SHOW');
      }, 7000);
      commit('SET_SHOW', { msg: message, timeID: timeout });
    },
  },
};
