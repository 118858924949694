import axios from 'axios';
import approveAPI from '@/api/approve';

// handling it using big number library
// api handling items
export default {
  getItem: (itemID) => {
    const url = `/api/item/getItem/${itemID}`;
    return axios.get(url).then((res) => {
      console.log(res.data);
      return res.data;
    });
  },
  setPrice: (token, newPrice, itemID) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const content = {
      value: newPrice,
    };
    const url = `/api/payment/setPrice/${itemID}`;
    return axios.post(url, content, { headers });
  },
  setLoyaltyFee: (token, newPrice, itemID) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const content = {
      value: newPrice,
    };
    const url = `/api/payment/setLoyaltyFee/${itemID}`;
    return axios.post(url, content, { headers });
  },
  addItem: (data, token, contentType, collectionID) => { // for adding item into
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    };
    const url = `/api/item/addItem?collectionID=${collectionID}&contentType=${contentType}`;
    return axios.post(url, data, { headers });
  }, // approve item for sale
  putOnSale: (token, tokenID, itemID) => new Promise((resolve, reject) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    console.log(tokenID);
    approveAPI.approveSale(tokenID).then(() => {
      let url = '';
      if (!tokenID || tokenID === 'unminted') {
        url = `/api/payment/putOnSale/${itemID}`;
      } else {
        // get approval
        url = `/api/payment/putOnSale/${itemID}?tokenID=${tokenID}`;
      }
      axios.post(url, {}, { headers }).then(() => resolve('ok')).catch((err) => reject(err));
    }).catch((err) => {
      console.log(err);
      return reject(err);
    });
  }),
  cancelSale: (token, itemID) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const url = `/api/payment/pullOffSale/${itemID}`;
    return axios.post(url, {}, { headers });
  },
  updateItem: (token, data, itemID) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const url = `/api/item/updateItem/${itemID}`;
    console.log(headers);
    console.log(data);
    return axios.post(url, data, { headers });
  },
  getItemList: (userID) => {
    const url = `/api/item/getUserItems/${userID}`;
    return axios.get(url);
  },
  getCreatedList: (userID) => {
    const url = `/api/item/getCreateditems/${userID}`;
    return axios.get(url);
  },
  getFavList: (userID) => {
    const url = `/api/item/favItems/${userID}`;
    return axios.get(url);
  },
  getRandomItem: () => {
    const url = '/api/item/random';
    return axios.get(url);
  },
};
