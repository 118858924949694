<template>
  <v-container class="mt-5">
    <v-row>
      <v-col cols="12">
        <h1 class="text-h4 text-center font-weight-bold mb-4">Top NFTs</h1>
        <p class="text-center mb-5 text--secondary">The top NFTs on OpenSea, ranked by volume,
          floor
          price and other
          statistics.
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        cols="12"
        lg="6"
        md="10"
        xl="4"
      >
        <section class="d-sm-flex align-center justify-center align-center filters-wrap">
          <v-select
            v-model="timeframe"
            :items="timeframeOptions"
            :menu-props="{ bottom: true, offsetY: true }"
            class="d-inline-flex primary--text selections-dropdown font-weight-bold"
            color="primary"
            flat
            outlined
            solo
          >
            <template v-slot:append>
              <v-icon
                class="selections-dropdown-icon"
                color="primary"
              >
                mdi-chevron-down
              </v-icon>
            </template>
            <template v-slot:selection="{ item }">
              <span class="subtitle-2 font-weight-bold">
                {{ item.text }}
              </span>
            </template>
          </v-select>
          <v-select
            v-model="category"
            :items="categoryOptions"
            :menu-props="{ bottom: true, offsetY: true }"
            class="d-inline-flex primary--text selections-dropdown font-weight-bold"
            color="primary"
            flat
            outlined
            solo
          >
            <template v-slot:selection="{ item }">
              <v-icon
                color="primary"
                left
              >mdi-grid
              </v-icon>
              <span class="subtitle-2 font-weight-bold">
                {{ item.text }}
              </span>
            </template>
            <template v-slot:append>
              <v-icon
                class="selections-dropdown-icon"
                color="primary"
              >
                mdi-chevron-down
              </v-icon>
            </template>
            <template v-slot:item="{ item }">
              <v-icon left>mdi-grid</v-icon>
              {{ item.text }}
            </template>
          </v-select>
          <v-select
            v-model="chain"
            :items="chainOptions"
            :menu-props="{ bottom: true, offsetY: true }"
            class="d-inline-flex primary--text selections-dropdown font-weight-bold"
            color="primary"
            flat
            outlined
            solo
          >
            <template v-slot:append>
              <v-icon
                class="selections-dropdown-icon"
                color="primary"
              >
                mdi-chevron-down
              </v-icon>
            </template>
            <template v-slot:selection="{ item }">
              <v-icon
                color="primary"
                left
              >$ethereumIcon
              </v-icon>
              <span class="subtitle-2 font-weight-bold">
                {{ item.text }}
              </span>
            </template>
            <template v-slot:item="{ item }">
              <v-icon left>$ethereumIcon</v-icon>
              {{ item.text }}
            </template>
          </v-select>
        </section>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="tableHeaders"
          :items="tableItems"
          class="transparent"
          hide-default-footer
          :items-per-page="-1"
        >
          <template v-slot:item.collection="{ item, index }">
            <section class="d-flex align-center justify-start py-3">
              <span class="font-weight-bold subtitle-2">{{ index }}</span>
              <v-avatar size="30" class="mx-3">
                <v-img :src="item.image" />
              </v-avatar>
              <v-icon
                v-if="item.verified"
                color="primary"
                small
                class="ml-n5 mt-auto mr-1"

              >
                mdi-check-decagram
              </v-icon>
              <span class="font-weight-bold">{{ item.collection }}</span>
            </section>
          </template>
          <template v-slot:item.volume="{ item }">
            <v-icon small left>
              $ethereumIcon
            </v-icon>
            <span class="font-weight-bold">
              {{ item.volume }}
            </span>
          </template>
          <template v-slot:item.twenty_four_hours="{ item, index }">
            <span
              class="font-weight-bold"
              :class="index % 2 === 0 ? 'green--text' : 'red--text'"
            >
              {{ (index % 2 === 0) ? '+' : '-' }}
              {{ item.twenty_four_hours }}%
            </span>
          </template>
          <template v-slot:item.seven_days="{ item, index }">
            <span
              class="font-weight-bold"
              :class="index % 2 === 0 ? 'green--text' : 'red--text'"
            >
              {{ (index % 2 === 0) ? '+' : '-' }}
              {{ item.seven_days }}%
            </span>
          </template>
          <template v-slot:item.floor_price="{ item }">
            <v-icon
              small
            >
              $ethereumIcon
            </v-icon>
            <span class="font-weight-bold">{{ item.floor_price }}</span>
          </template>
          <template v-slot:item.owners="{ item }">
            <span class="font-weight-bold">{{ item.owners }}</span>
          </template>
          <template v-slot:item.items="{ item }">
            <span class="font-weight-bold">{{ item.items }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

function kFormatter(num) {
  return Math.abs(num) > 999
    ? `${Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))}k`
    : Math.sign(num) * Math.abs(num);
}

export default {
  name: 'Statistics',
  props: {},
  data: () => ({
    tableHeaders: [
      {
        text: 'Collection',
        align: 'left',
        value: 'collection',
      },
      {
        text: 'Volume',
        align: 'left',
        value: 'volume',
      },
      {
        text: '24h %',
        align: 'left',
        value: 'twenty_four_hours',
      },
      {
        text: '7d %',
        align: 'right',
        value: 'seven_days',
      },
      {
        text: 'Floor Price',
        align: 'left',
        value: 'floor_price',
      },
      {
        text: 'Owners',
        align: 'left',
        value: 'owners',
      },
      {
        text: 'Items',
        align: 'left',
        value: 'items',
      },
    ],
    timeframe: 'seven-days',
    category: 'all',
    chain: 'eth',
    timeframeOptions: [
      {
        text: 'Last 7 days',
        value: 'seven-days',
      },
      {
        text: 'Last 24 hours',
        value: 'twenty-four-hours',
      },
      {
        text: 'Last 30 days',
        value: 'thirty-days',
      },
    ],
    categoryOptions: [
      {
        text: 'All Categories',
        value: 'all',
      },
      {
        text: 'New',
        value: 'collectibles',
      },
      {
        text: 'Art',
        value: 'art',
      },
    ],
    chainOptions: [
      {
        text: 'Ethereum',
        value: 'eth',
      },
      {
        text: 'Binance Smart Chain',
        value: 'bsc',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      collections: 'collections/all',
    }),
    tableItems() {
      return this.collections.map((collection) => ({
        collection: collection.name,
        image: collection.imageUrl,
        verified: collection.isVerified,
        volume: Number(Math.floor(Math.random() * 10000))
          .toFixed(2),
        twenty_four_hours: Number(Math.floor(Math.random() * 100))
          .toFixed(2),
        seven_days: Number(Math.floor(Math.random() * 100))
          .toFixed(2),
        floor_price: Number(Math.floor(Math.random() * 100)),
        owners: kFormatter(Number(Math.floor(Math.random() * 50000))),
        items: kFormatter(Number(Math.floor(Math.random() * 50000))),
      }));
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.filters-wrap {
  gap: 20px;
}
</style>
