import Vuex from 'vuex';
import user from './user';
import nfts from './nft';
import alert from './alerts';

export default new Vuex.Store({
  state: {
    mobileDrawerActive: false,
  },
  getters: {
    mobileDrawerIsActive: (state) => state.mobileDrawerActive,
  },
  actions: {
    toggleMobileDrawer({ state, commit }) {
      commit('SET_MOBILE_DRAWER_ACTIVE', !state.mobileDrawerActive);
    },
  },
  mutations: {
    SET_MOBILE_DRAWER_ACTIVE: (state, payload) => {
      state.mobileDrawerActive = payload;
    },
  },
  modules: {
    user,
    nfts,
    alert,
  },
});
