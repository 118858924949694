import axios from 'axios';

// api handling items
export default {
  getCollection: (collectionID) => {
    const url = `/api/collection/getCollection/${collectionID}`;
    return axios.get(url).then((res) => {
      console.log(res.data);
      return res.data;
    });
  }, // axios.post(url, collectionData, { headers })
  getCollectionItems: (collectionID) => {
    const url = `/api/collection/getCollectionItems/${collectionID}`;
    return axios.get(url).then((res) => {
      console.log(res.data);
      return res.data;
    });
  }, // axios
  getUserCollections: (userID) => { // getting collection items;
    const url = `/api/collection/userCollections/${userID}`;
    return axios.get(url);
  },
  updateUserCollections: (token) => { // for initial user select
    const url = '/api/item/updateItem';
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    console.log(headers);
    return axios.get(url, { headers });
  },
  updateFile: (token) => { // for updating user file
    console.log(token);
  },
};
