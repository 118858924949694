<template>
  <v-container fluid class="py-0 px-8">
    <v-row>
      <v-col cols="12">
        <section class="py-10">
          <section class="d-flex flex-row justify-space-between">
            <page-header class="mb-8">Offer Settings</page-header>
            <v-btn color="secondary" outlined>
              View My Offers
            </v-btn>
          </section>

          <profile-offer-settings-form />
        </section>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ProfileNotificationSettings',
  components: {
    'profile-offer-settings-form': () => import('@/features/forms/ProfileOfferSettingsForm.vue'),
  },
  methods: {
    updateView(payload) {
      this.activeView = payload;
    },
  },
};
</script>
