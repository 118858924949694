import axios from 'axios';

export default {
  // minVal and maxVal are numbers
  // onSale
  getNFTS: async ({ commit, rootState },
    {
      page, search, category, onSale, blockchain, currency, minVal, maxVal,
    }) => {
    console.log('getting nfts');
    console.log(search);
    console.log(page);
    console.log(blockchain);
    let url = `/api/search/v1/items?p=${page}&s=${search}&category=${category}`;
    url += (onSale !== undefined ? `&onSale=${onSale}` : '');
    url += (blockchain !== undefined ? `&blockchain=${blockchain}` : '');
    url += (currency !== undefined ? `&currency=${currency}` : '');
    url += (minVal !== undefined ? `&minVal=${minVal}` : '');
    url += (maxVal !== undefined ? `&maxVal=${maxVal}` : '');
    return axios.get(url).then((res) => {
      commit('SET_NFTS', { nfts: res.data, likes: rootState.user.profile.likes });
      return res.data.count;
    });
  },
  likeNFT: async ({ commit }, { itemID, token }) => {
    console.log('like nft');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const url = `/api/item/likeItem/${itemID}`;
    axios.put(url, {}, config).then((data) => {
      console.log(data);
      commit('user/LIKE_ITEM', { itemID }, { root: true });
      commit('LIKE_ITEM', { publicID: itemID });
    });
  },
  unlikeNFT: async ({ commit }, { itemID, token }) => {
    console.log('unlikenft');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const url = `/api/item/unlikeItem/${itemID}`;
    axios.put(url, {}, config).then((data) => {
      console.log(data);
      commit('user/UNLIKE_ITEM', { itemID }, { root: true });
      commit('UNLIKE_ITEM', { publicID: itemID });
    });
  },
};
